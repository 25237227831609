import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { ICAuthService } from '../../../auth/service/i-c-auth.service';
import { SettingsService } from '../../settings/service/settings.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { environment } from 'src/environments/environment';
import { OnboardingStep } from './onboarding-box.service';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { UploadDiplomaModalComponent } from '../../../shared/components/upload-diploma-modal/upload-diploma-modal.component';
import { RouterLink } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OnboardingProgressBarComponent } from '../../../shared/components/onboarding-progress-bar/onboarding-progress-bar.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-onboarding-box',
  templateUrl: './onboarding-box.component.html',
  styleUrls: ['./onboarding-box.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    OnboardingProgressBarComponent,
    AngularSvgIconModule,
    NgSwitch,
    NgSwitchCase,
    RouterLink,
    NgClass,
    UploadDiplomaModalComponent,
    TranslateModule,
  ],
})
export class OnboardingBoxComponent implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private alertService: AlertService,
    private auth: ICAuthService,
    private profileService: ProfileService,
    private officeService: OfficeService
  ) {}

  env = environment;
  _currentUser: User;

  onboardingStep: OnboardingStep;
  ONBOARDINGSTEP = OnboardingStep;

  showUploadModal: boolean;
  requestedMail: boolean;
  subscriptions: Array<any> = [];
  subscriptionLoaded: boolean = false;

  ngOnInit() {
    this.auth.getCurrentUser().subscribe((user: any) => {
      this._currentUser = user;

      if (user && user.type === 'Therapist') {
        this.settingsService.getActiveSubscriptions().subscribe((sub: any) => {
          this.subscriptions = sub;
          this.subscriptionLoaded = true;
          this.assignOnboardingStep();
        });
      }
    });
  }

  assignOnboardingStep() {
    if (!this._currentUser.profile.profile_completed) {
      this.onboardingStep = OnboardingStep.fill_out_profile;
      return;
    }
    if (!this._currentUser.profile.profile_approved) {
      this.onboardingStep = OnboardingStep.waiting_for_approval;
      return;
    }
    if (this.subscriptions.length == 0) {
      this.onboardingStep = OnboardingStep.subscribing;
      return;
    }
    this.onboardingStep = OnboardingStep.done;
  }

  canClose() {
    return this._currentUser.email_confirmed;
  }

  shouldDisplay() {
    if (!this.subscriptionLoaded) {
      return false;
    }
    if (this._currentUser.type == 'Client') {
      return false;
    }
    // Fallback: Email not verified yet?
    if (!this._currentUser.email_confirmed) {
      return true;
    }
    if (this._currentUser.profile.ignoreProfile) {
      return false;
    }
    // Fallback: Calendar not setup
    if (
      this._currentUser.profile.calendar_enabled &&
      this.onboardingStep != OnboardingStep.done
    ) {
      return true;
    }
    if (!this._currentUser.profile.calendar_enabled) {
      if (this._currentUser.profile.ignoreCalendar) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  resendWelcomeMail() {
    var self = this;
    this.auth.resendWelcomeMail().subscribe(() => {
      self.alertService.success('onboarding.email_sent');
      self.requestedMail = true;
    });
  }

  setIgnoreProfile() {
    this._currentUser.profile.ignoreProfile = true;
    this.profileService.updateItem(
      this._currentUser.profile,
      this._currentUser.profile.id
    );
  }

  setIgnoreCalendar() {
    this._currentUser.profile.ignoreCalendar = true;
    this.profileService.updateItem(
      this._currentUser.profile,
      this._currentUser.profile.id
    );
  }

  onDiplomasUploaded(diplomas: any) {
    this._currentUser.profile.diplomas = diplomas;
    this.showUploadModal = false;
  }
}
