import { Appointment } from './appointment.model';
import { Profile } from './profile.model';

export class Client {
  email: string;
  publicKey: string;
  id: number;
  name: string;
  profile: Profile;
  created_at: Date;
  cannot_book_eap_session_reason: string;
  can_book_couples_eap_session: boolean;

  // Client Center Data
  last_contact: string;
  total_appointments: number;
  earnings: number;
  next_appointment: Appointment;
}
