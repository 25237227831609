<div
  class="ic-navbar container-fluid"
  *ngIf="(componentIsntAuth() && currentUser) || !componentIsntAuth()"
>
  <nav
    class="navbar is-transparent"
    [ngClass]="{ 'dropdown-is-active': showDropDown }"
  >
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        (click)="toggleDropDown()"
        aria-expanded="false"
        data-target="main-menu"
        [ngClass]="{ 'is-active': showDropDown }"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      <a class="navbar-item logo" (click)="homelink()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="182px"
          height="24px"
          viewBox="0 0 182 24"
          version="1.1"
        >
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Logo-Sofia" fill="#373736">
              <g id="Group-18">
                <path
                  d="M16.586849,6.412125 L17.843992,3.817875 C17.0219271,3.496125 16.6108947,2.953125 16.6108947,2.188875 C16.6108947,1.657875 16.8122781,1.207875 17.2150448,0.837375 C17.61706,0.466875 18.1054898,0.282375 18.6773283,0.282375 C19.2589354,0.282375 19.7526252,0.466875 20.1599004,0.837375 C20.5664243,1.207875 20.7693105,1.657875 20.7693105,2.188875 C20.7693105,2.455125 20.7324904,2.695875 20.6573474,2.913375 C20.5822043,3.130125 20.391341,3.496125 20.0847574,4.010625 L18.6532825,6.412125 L16.586849,6.412125 Z"
                  id="Fill-1"
                />
                <polygon
                  id="Fill-3"
                  points="0 17.813925 3.6151316 17.813925 3.6151316 0.790425 0 0.790425"
                />
                <polygon
                  id="Fill-5"
                  points="13.9884027 6.275175 13.9884027 8.993175 11.5620339 8.993175 11.5620339 17.813925 8.1460314 17.813925 8.1460314 8.993175 6.04202632 8.993175 6.04202632 6.275175 8.1460314 6.275175 8.1460314 1.853175 11.5620339 1.853175 11.5620339 6.275175"
                />
                <path
                  d="M23.652098,13.0698 C20.1865011,12.1308 20.1609524,10.37655 20.1865011,9.31455 C20.2112983,7.6098 22.265709,6.02805 24.6920777,6.02805 C26.993709,6.02805 29.4200777,7.0413 29.4448749,9.8583 L26.2017013,9.8583 C26.1769041,8.9928 25.4344909,8.84505 24.8897039,8.84505 C24.1472907,8.84505 23.652098,9.1908 23.6768952,9.7098 C23.700941,10.05555 23.9985074,10.4013 24.6920777,10.57455 L26.6472996,11.0928 C29.4448749,11.8593 29.7912843,13.7118 29.7912843,14.45355 C29.7912843,16.8993 27.2168838,18.06105 24.7912665,18.06105 C22.4392894,18.06105 19.9881235,16.65255 19.9633263,14.08305 L23.1817026,14.08305 C23.2064998,14.7498 23.9496645,15.4173 24.8897039,15.4173 C25.6569143,15.39255 26.3001387,15.0468 26.3001387,14.45355 C26.3001387,13.96005 26.0040751,13.6878 25.1624731,13.46505 L23.652098,13.0698 Z"
                  id="Fill-6"
                />
                <path
                  d="M51.9305275,14.330325 C50.4449497,16.578825 47.8457519,18.061575 44.7513616,18.061575 C39.8753298,18.061575 36.186558,14.132325 36.186558,9.339075 C36.186558,4.496325 39.8753298,0.592575 44.7513616,0.592575 C47.796909,0.592575 50.3713095,2.025825 51.8561359,4.200075 L48.9105288,6.225825 C47.9945352,4.891575 46.5089573,4.002075 44.7513616,4.002075 C41.9049433,4.002075 39.8257354,6.374325 39.8257354,9.339075 C39.8257354,12.279825 41.9049433,14.651325 44.7513616,14.651325 C46.5337545,14.651325 48.093724,13.688325 49.0097176,12.303825 L51.9305275,14.330325 Z"
                  id="Fill-7"
                />
                <path
                  d="M61.9838395,12.0078 C61.9838395,10.4508 60.7958281,9.3888 59.3598446,9.3888 C57.9486584,9.3888 56.7606469,10.4508 56.7606469,12.0078 C56.7606469,13.61355 57.9486584,14.6508 59.3598446,14.6508 C60.7958281,14.6508 61.9838395,13.61355 61.9838395,12.0078 M53.3686901,12.0078 C53.3686901,8.37555 56.0918739,6.02805 59.3598446,6.02805 C62.627064,6.02805 65.3757963,8.37555 65.3757963,12.0078 C65.3757963,15.64005 62.627064,18.01155 59.3598446,18.01155 C56.0918739,18.01155 53.3686901,15.64005 53.3686901,12.0078"
                  id="Fill-8"
                />
                <path
                  d="M78.6451552,11.711175 L78.6451552,17.813925 L75.2043555,17.813925 L75.2043555,11.587425 C75.1795583,10.130175 74.2883619,9.363675 73.298728,9.363675 C72.2587484,9.363675 71.2931603,9.956925 71.2931603,11.711175 L71.2931603,17.813925 L67.8523606,17.813925 L67.8523606,6.275175 L71.2931603,6.275175 L71.2931603,7.955175 C71.8371959,6.744675 73.0755532,6.053175 74.3131591,6.053175 C76.1451463,6.053175 77.3827522,6.744675 78.026728,8.078925 C79.1403479,6.275175 80.6747687,6.028425 81.492325,6.028425 C84.2395545,6.028425 85.9483072,7.782675 85.9483072,11.266425 L85.9483072,17.813925 L82.5315532,17.813925 L82.5315532,11.661675 C82.5315532,10.154925 81.6403568,9.363675 80.6259257,9.363675 C79.5371031,9.363675 78.6451552,10.031175 78.6451552,11.711175"
                  id="Fill-9"
                />
                <path
                  d="M95.1597917,14.823975 C96.619821,14.823975 97.8333811,13.737225 97.8333811,12.081975 C97.8333811,10.376475 96.619821,9.289725 95.1597917,9.289725 C93.7238083,9.289725 92.4862024,10.426725 92.4862024,12.081975 C92.4862024,13.810725 93.7486055,14.823975 95.1597917,14.823975 Z M92.4118108,6.275475 L92.4118108,7.708725 C92.981395,6.645975 94.3670327,6.027975 95.8030161,6.027975 C98.7741719,6.027975 101.398167,8.375475 101.398167,12.007725 C101.398167,15.714225 98.7741719,18.060975 95.8030161,18.060975 C94.3670327,18.060975 92.981395,17.467725 92.4118108,16.404975 L92.4118108,23.471475 L88.9958083,23.471475 L88.9958083,6.275475 L92.4118108,6.275475 Z"
                  id="Fill-10"
                />
                <polygon
                  id="Fill-11"
                  points="103.90028 17.813925 107.341079 17.813925 107.341079 -7.5e-05 103.90028 -7.5e-05"
                />
                <path
                  d="M110.510613,17.813925 L113.926615,17.813925 L113.926615,6.275175 L110.510613,6.275175 L110.510613,17.813925 Z M112.218614,-7.5e-05 C113.381828,-7.5e-05 114.322619,0.863925 114.322619,2.000925 C114.322619,3.137175 113.381828,4.026675 112.218614,4.026675 C111.0554,4.026675 110.065015,3.137175 110.065015,2.000925 C110.065015,0.863925 111.0554,-7.5e-05 112.218614,-7.5e-05 Z"
                  id="Fill-12"
                />
                <path
                  d="M122.690473,6.02835 C124.744884,6.02835 126.601668,6.91785 127.715288,8.47485 L124.918464,10.47585 C124.448069,9.7341 123.581669,9.31485 122.690473,9.31485 C121.230444,9.31485 119.819257,10.3521 119.819257,12.0081 C119.819257,13.66335 121.230444,14.77485 122.690473,14.77485 C123.581669,14.77485 124.448069,14.35485 124.918464,13.6131 L127.715288,15.5901 C126.626465,17.14635 124.770432,18.06135 122.690473,18.06135 C119.373659,18.06135 116.402503,15.6396 116.402503,12.0081 C116.402503,8.37585 119.373659,6.02835 122.690473,6.02835"
                  id="Fill-13"
                />
                <path
                  d="M135.713513,14.774925 C137.124699,14.774925 138.511088,13.761675 138.511088,12.032175 C138.511088,10.302675 137.124699,9.290175 135.713513,9.290175 C134.278281,9.290175 132.8175,10.376925 132.8175,12.032175 C132.8175,13.688175 134.278281,14.774925 135.713513,14.774925 Z M138.511088,16.504425 C137.767924,17.615925 136.035877,18.036675 135.143929,18.036675 C132.198322,18.036675 129.251963,15.738675 129.251963,12.032175 C129.251963,8.325675 132.198322,6.028425 135.143929,6.028425 C135.886342,6.028425 137.619892,6.275175 138.511088,7.559925 L138.511088,6.275175 L141.927091,6.275175 L141.927091,17.813925 L138.511088,17.813925 L138.511088,16.504425 Z"
                  id="Fill-14"
                />
                <polygon
                  id="Fill-15"
                  points="152.029246 6.275175 152.029246 8.993175 149.602877 8.993175 149.602877 17.813925 146.186875 17.813925 146.186875 8.993175 144.082869 8.993175 144.082869 6.275175 146.186875 6.275175 146.186875 1.853175 149.602877 1.853175 149.602877 6.275175"
                />
                <path
                  d="M161.75824,11.019075 C161.585411,9.610575 160.298211,9.042825 159.331872,9.042825 C158.367035,9.042825 157.055038,9.561825 156.758223,11.019075 L161.75824,11.019075 Z M164.580613,15.714075 C163.417399,17.442825 161.263799,18.061575 159.307826,18.061575 C155.990261,18.061575 153.267077,15.639825 153.267077,12.007575 C153.267077,8.376075 155.990261,6.028575 159.307826,6.028575 C162.599842,6.028575 165.223837,8.474325 165.223837,12.106575 C165.223837,12.402825 165.19904,12.872325 165.174994,13.168575 L156.708628,13.168575 C156.882209,14.503575 158.342238,15.096075 159.555047,15.096075 C160.495837,15.096075 161.51102,14.750325 162.080604,14.008575 L164.580613,15.714075 Z"
                  id="Fill-16"
                />
                <path
                  d="M173.903446,9.49775 C172.480988,9.167 171.052519,9.95 170.675301,11.5625 C170.286812,13.22225 171.220088,14.5565 172.641794,14.888 C174.040958,15.2135 175.504744,14.3885 175.882714,12.776 C176.276463,11.0915 175.277061,9.81875 173.903446,9.49775 Z M174.631582,18.44675 L174.957703,17.05175 C174.161187,17.95625 172.670349,18.2435 171.271937,17.918 C168.37893,17.243 166.359085,14.3615 167.187161,10.82525 C168.031769,7.21625 171.121651,5.52575 174.013906,6.20075 C175.41307,6.52625 176.627381,7.41875 176.939976,8.582 L178.690809,1.1 L182.017391,1.8755 L177.958164,19.22225 L174.631582,18.44675 Z"
                  id="Fill-17"
                />
              </g>
            </g>
          </g>
        </svg>
      </a>

      <app-invoice-amount
        *ngIf="getComponent() != 'LoginComponent'"
        class="navbar-item is-hidden-desktop"
      >
      </app-invoice-amount>

      <span
        *ngIf="unreadMessagesCount"
        class="notifications-badge burger-notification-badge is-hidden-desktop"
        >{{ unreadMessagesCount }}</span
      >
    </div>

    <!-- Begin Navbar menu -->

    <div
      id="main-nav"
      class="navbar-menu"
      [ngClass]="{ 'is-active': showDropDown }"
    >
      <!-- Begin navbar END -->

      <div class="navbar-end">
        <!-- Auth pages navbar end - DESKTOP -->

        <ng-container *ngIf="!currentUser" class="is-hidden-touch">
          <!-- If page is Login -->

          <ng-container *ngIf="getComponent() == 'LoginComponent'">
            <p class="navbar-item">Don't have an account?</p>
            <div class="navbar-item">
              <div class="buttons">
                <button
                  (click)="showStepsBoolean = true"
                  routerLink="/sign-up"
                  class="button is-primary is-rounded"
                >
                  Sign up
                </button>
              </div>
            </div>
          </ng-container>

          <!-- If page is Register -->

          <ng-container *ngIf="getComponent() == 'RegisterComponent'">
            <p class="navbar-item">Already have an account?</p>
            <div class="navbar-item">
              <div class="buttons">
                <button
                  (click)="showStepsBoolean = false"
                  routerLink="/login"
                  class="is-rounded button is-primary"
                >
                  Login
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="currentUser?.type == 'Therapist'">
          <app-invoice-amount
            *ngIf="getComponent() != 'LoginComponent'"
          ></app-invoice-amount>
<!--          <shared-add-new-button-->
<!--            *ngIf="getComponent() != 'LoginComponent'"-->
<!--          ></shared-add-new-button>-->
        </ng-container>

        <div
          class="navbar-item has-dropdown"
          *ngIf="getComponent() != 'LoginComponent'"
          [ngClass]="{ 'is-active': showDropDown }"
        >
          <a
            *ngIf="currentUser && currentUser.profile"
            class="navbar-item"
            [ngClass]="{
              'no-click':
                !currentUser.profile.profile_completed &&
                !currentUser.profile.profile_approved &&
                currentUser.type == 'Therapist' &&
                getComponent() == 'ProfileComponent'
            }"
          >
            <p class="navbar-item" routerLink="/">
              {{ "navigation.dashboard" | translate }}
            </p>
            <div style="padding-top: 5px; width: 45px">
              <app-profile-picture
                (click)="toggleDropDown()"
                [notifications]="unreadMessagesCount"
                [variant]="'mini'"
                [profile_id]="currentUser.profile.id"
              ></app-profile-picture>
            </div>
          </a>

          <div
            class="main-menu navbar-dropdown is-right has-padding-0"
            [ngClass]="{ 'is-active': showDropDown }"
          >
            <span class="triangle"></span>
            <div class="dropdown-container has-padding-y-10">
              <!-- Current user photo and details -->

              <div *ngIf="currentUser" (click)="toggleDropDown()" class="media">
                <div class="media-left">
                  <div style="width: 45px">
                    <app-profile-picture
                      (click)="toggleDropDown()"
                      [variant]="'thumbnail'"
                      [profile_id]="currentUser.profile.id"
                    ></app-profile-picture>
                  </div>
                </div>
                <div routerLink="/" class="media-content pointer">
                  <span>{{ "navigation.dashboard" | translate }}</span>
                  <p class="title is-6">
                    {{ currentUser.name }}
                  </p>
                </div>
              </div>

              <!-- Register/Login Menu -->

              <ng-container *ngIf="!componentIsntAuth()">
                <a
                  [href]="env.baseUrl + '/find-a-therapist'"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  {{ "navigation.find_a_therapist" | translate }}
                </a>
                <a
                  *ngIf="getComponent() == 'LoginComponent'"
                  routerLink="/register"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  {{ "navigation.sign_up" | translate }}
                </a>
                <hr class="navbar-divider" />

                <a
                  *ngIf="getComponent() == 'RegisterComponent'"
                  routerLink="/login"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  {{ "navigation.login" | translate }}
                </a>
              </ng-container>

              <!-- Therapist Menu -->
              <ng-container
                *ngIf="currentUser?.type == 'Therapist' && componentIsntAuth()"
              >
                <a
                  (click)="openCommunity()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-community"></span>
                  <span>{{ "navigation.community" | translate }}</span>
                </a>
                <a
                  (click)="toggleDropDown()"
                  routerLink="/home/messaging"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-messages">
                    <span
                      *ngIf="unreadMessagesCount"
                      class="notifications-badge header-picture-badge"
                      >{{ unreadMessagesCount }}</span
                    >
                  </span>
                  {{ "navigation.messages" | translate }}
                </a>
                <a
                  *ngIf="myOfficeAvailable"
                  [routerLink]="
                    currentUser.profile
                      .stripe_connect_has_currently_due_requirements
                      ? ['/office/settings']
                      : ['/office/dashboard']
                  "
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-invoice"></span>
                  {{ "navigation.office" | translate }}
                </a>
                <a
                  routerLink="/home/calendar"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-calendar"></span>
                  {{ "navigation.calendar" | translate }}
                </a>
                <a routerLink="/home/video-waiting-room" class="navbar-item">
                  <span class="icon icon-waiting_room"></span>
                  {{ "navigation.waiting_room" | translate }}
                </a>
                <hr class="navbar-divider"/>
                <a
                  routerLink="/home/my_clients"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-my-clients"></span>
                  {{ "navigation.clients" | translate }}
                </a>
                <a
                  (click)="showInviteClientsModal()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                  *ngIf="currentUser.profile.availability !== 'profile_hidden'"
                >
                  <span class="icon icon-invite-client"></span>
                  {{ "navigation.invite_clients" | translate }}
                </a>
                <hr class="navbar-divider" />
                <a
                  (click)="toggleDropDown()"
                  routerLink="/home/invites"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-referral-program"></span>
                  {{ "navigation.refer_colleague" | translate }}
                </a>
                <hr class="navbar-divider" />
                <a
                  [routerLink]="['/home/profile']"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-edit-profile"></span>
                  {{ "navigation.edit_profile" | translate }}
                </a>
                <a
                  *ngIf="env.name != 'production'"
                  (click)="openEditProfileV2()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-edit-profile"></span>
                  {{ "navigation.edit_profile_v2" | translate }}
                </a>
                <a
                  routerLink="/home/settings/subscription"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="navbar-item"
                >
                  <span class="icon icon-subscription"></span>
                  {{ "navigation.subscription" | translate }}
                </a>
                <a
                  routerLink="/home/settings"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="navbar-item"
                >
                  <span class="icon icon-settings"></span>
                  {{ "navigation.settings" | translate }}
                </a>
                <hr class="navbar-divider" />
                <a
                  (click)="showDirectory()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-list"></span>
                  {{ "navigation.directory" | translate }}
                </a>
                <a
                  (click)="viewProfile()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-my-clients"></span>
                  {{ "navigation.view_profile" | translate }}
                </a>
                <hr class="navbar-divider" />
              </ng-container>

              <!-- Client Menu -->
              <ng-container
                *ngIf="currentUser?.type == 'Client' && componentIsntAuth()"
              >
                <a
                  (click)="openCommunity()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-community"></span>
                  <span>{{ "navigation.community" | translate }}</span>
                  <span
                    class="tag is-primary is-rounded has-margin-left-5 has-text-weight-medium"
                    style="border-radius: 6px"
                  >{{ "navigation.new" | translate }}</span
                  >
                </a>
                <a
                  routerLink="/home/messaging"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-messages">
                    <span *ngIf="unreadMessagesCount">{{
                      unreadMessagesCount
                    }}</span>
                  </span>
                  {{ "navigation.messages" | translate }}
                </a>
                <a
                  routerLink="/home/calendar"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-calendar"></span>
                  {{ "navigation.calendar" | translate }}
                </a>
                <!-- <a routerLink="/home/journal" (click)="toggleDropDown()" routerLinkActive="is-active-nav" class="navbar-item">
                                <span class="icon icon-journal"></span>Journal
                            </a> -->
                <a
                  *ngIf="env.name != 'production'"
                  routerLink="/home/settings/profile"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon icon-my-clients"></span
                  >{{ "client_side.my_profile" | translate }}
                </a>
                <a
                  routerLink="/home/settings"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon icon-settings"></span>
                  {{ "navigation.settings" | translate }}
                </a>
                <a
                  class="directory-icon-container is-flex align-center navbar-item"
                  href="{{ env.baseUrl + '/find-a-therapist' }}"
                >
                  <span
                    style="left: 10px !important"
                    class="icon icon-list"
                  ></span>
                  <p>{{ "navigation.find_a_therapist" | translate }}</p>
                </a>

                <a
                  routerLink="/home/favourites"
                  (click)="toggleDropDown()"
                  routerLinkActive="is-active-nav"
                  class="navbar-item"
                >
                  <span class="icon icon-favorite"></span>
                  {{ "navigation.my_favorite_therapists" | translate }}
                </a>
              </ng-container>

              <!-- Logout button -->
              <a *ngIf="currentUser" (click)="logout()" class="navbar-item">
                <span class="icon icon-logout"></span>
                {{ "navigation.logout" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <ng-container *ngIf="currentUser">
    <app-invite-clients [user]="currentUser"></app-invite-clients>
  </ng-container>

  <ng-container *ngIf="currentUser && currentUser.type == 'Therapist'">
    <office-invoice-create-modal
      [currentUser]="currentUser"
    ></office-invoice-create-modal>
    <shared-office-payout-modal></shared-office-payout-modal>
    <shared-waiting-room-announcement
      [currentUser]="currentUser"
    ></shared-waiting-room-announcement>
    <shared-note-modal></shared-note-modal>
<!--    <shared-new-client-modal></shared-new-client-modal>-->
  </ng-container>

  <shared-support-modal></shared-support-modal>
</div>

<div class="container" style="display: none">
  <div
    class="notification is-danger subscription-issues"
    *ngIf="shouldShowSubscriptionErrorBox"
  >
    <strong>Subscription Issues</strong><br />
    Your subscription is not active. Please update your
    <a [routerLink]="['/home/settings/subscription']">payment method</a>. You
    might not access all functionalities in the meantime.
  </div>
</div>
