import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Profile } from '../../../entities/profile.model';
import { Client } from '../../../entities/client.model';
import { User } from '../../../entities/user.model';
import { ClientsService } from '../../my-clients/service/clients.service';
import { SortingService } from '../../../shared/services/sorting/sorting.service';
import { Appointment } from '../../../entities/appointment.model';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { SharedService } from '../../../shared/services/shared.service';
import moment from 'moment';
import { CalendarService } from '../../calendar/service/calendar.service';
import { Message } from '../../../entities/message.model';
import { MessagingService } from '../../messaging/service/messaging.service';
import { SubmitActionType } from '../submit-action-type';
import { PaymentType } from '../../../entities/PaymentType';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { BookingFormDetailsComponent } from '../booking-form-details/booking-form-details.component';
import { BookingFormBlockerSummaryComponent } from '../booking-form-blocker-summary/booking-form-blocker-summary.component';
import { BookingFormSelectDateComponent } from '../booking-form-select-date/booking-form-select-date.component';
import { BookingFormSelectClientComponent } from '../booking-form-select-client/booking-form-select-client.component';
import {
  AsyncPipe,
  NgClass,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';

type Step =
  | 'selectClient'
  | 'selectDate'
  | 'createAppointment'
  | 'createBlocker';

@Component({
  selector: 'app-booking-form-container',
  templateUrl: './booking-form-container.component.html',
  styleUrls: ['./booking-form-container.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgSwitch,
    FormsModule,
    ReactiveFormsModule,
    NgSwitchCase,
    BookingFormSelectClientComponent,
    BookingFormSelectDateComponent,
    BookingFormBlockerSummaryComponent,
    BookingFormDetailsComponent,
    MatProgressSpinner,
    AsyncPipe,
    TranslateModule,
  ],
})
export class BookingFormContainerComponent implements OnInit, OnDestroy {
  private currentStep: BehaviorSubject<Step> = new BehaviorSubject<Step>(
    undefined
  );

  isShowingModal: boolean;
  currentStep$: Observable<Step> = this.currentStep.asObservable();
  requestBookingForm: FormGroup;
  timezoneText: string;
  isReady: boolean = false;
  skipDateTimeSelection: boolean;
  clients: Client[];
  subscription: any;
  editingAppointment: Appointment;
  isBusy: boolean;
  preselectedClient: User;

  @Input() currentUser: User;
  @Output() appointmentConfigured = new EventEmitter<Partial<Appointment>>();
  profile: Profile;
  submitActionType: SubmitActionType;
  isEAPTherapist: boolean;
  isEditingEAPSession: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private sortingService: SortingService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,
    private calendarService: CalendarService,
    private messageService: MessagingService
  ) {}

  ngOnInit() {
    this.profile = this.currentUser.profile;
    this.timezoneText =
      Intl.DateTimeFormat().resolvedOptions().timeZone !==
      this.currentUser.profile.timezone
        ? this.currentUser.profile.timezone
        : undefined;
    this.isEAPTherapist = this.currentUser.profile.eap;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showForNewEventBooking(timestampUTC: number, timeNotSet: boolean) {
    this.skipDateTimeSelection = !timeNotSet;
    this.editingAppointment = undefined;
    this.isShowingModal = true;

    // load client list
    this.clientsService.getClients().subscribe((clients) => {
      clients = clients.filter((c) => !c['deleted_at']);
      this.clients = this.sortingService.sortAlphabetically(clients, 'name');
      this.initializeForm({
        initialDate: new Date(timestampUTC),
      });
      this.currentStep.next('selectClient');
      this.isReady = true;
      this.submitActionType = SubmitActionType.schedule;
      this.cdr.detectChanges();
    });
  }

  showForEditingAppointment(appointment: Appointment) {
    this.editingAppointment = appointment;
    this.isShowingModal = true;

    // load client list
    const duration = Math.round(
      (appointment.end.getTime() - appointment.start.getTime()) / 60 / 1000
    );
    this.initializeForm({
      initialDate: appointment.start,
      client: appointment.client,
      appointmentDuration: duration,
      prepayPrice: appointment.prepay_price,
      isOnline: appointment.is_online,
      isCouplesSession: appointment.is_couples_session,
      paymentType: appointment.payment_type as PaymentType,
      isFullDay: false,
      isBlocker: false,
      note: appointment.note,
    });
    this.currentStep.next('createAppointment');
    this.isReady = true;
    this.submitActionType =
      appointment.status === 'inquiry'
        ? SubmitActionType.confirm
        : SubmitActionType.update;
    // we want to know this because we should still show the EAP option if we are editing something that is already EAP,
    // otherwise we might hide it because of budget allowances
    this.isEditingEAPSession = appointment.payment_type === PaymentType.eap;
    this.cdr.detectChanges();
  }

  showForNewClientBooking(client: User | LeanConversationUser) {
    this.initializeForm({
      initialDate: this.todayAtBeginningOfDay(),
      client,
    });
    this.skipDateTimeSelection = false;
    this.currentStep.next('selectDate');
    this.editingAppointment = undefined;
    this.isShowingModal = true;
    this.isReady = true;
    this.submitActionType = SubmitActionType.schedule;
    this.cdr.detectChanges();
  }

  private todayAtBeginningOfDay() {
    const today = new Date();
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  }

  private initializeForm({
    initialDate,
    client = null,
    appointmentDuration = this.profile.appointment_duration,
    prepayPrice = this.profile.price_per_session,
    isOnline = true,
    isCouplesSession = false,
    paymentType = this.profile.stripe_connect_active
      ? PaymentType.prepay
      : PaymentType.invoice_later,
    isFullDay = false,
    isBlocker = false,
    note = '',
  }) {
    this.preselectedClient = client;

    this.requestBookingForm = this.formBuilder.group(
      {
        appointmentDate: initialDate,
        duration: new FormControl(appointmentDuration, [
          Validators.required,
          this.durationValidator,
        ]),
        client,
        prepayPrice,
        isOnline,
        isCouplesSession: client?.can_book_couples_eap_session
          ? isCouplesSession
          : false,
        paymentType,
        isFullDay,
        isBlocker,
        note,
      },
      { validators: this.isMinimumPriceValidator }
    );
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.requestBookingForm.valueChanges.subscribe(
      (value) => {
        if (value.isFullDay) {
          this.requestBookingForm.controls.duration.disable({
            emitEvent: false,
          });
        } else {
          this.requestBookingForm.controls.duration.enable({
            emitEvent: false,
          });
        }
      }
    );
  }

  onClientSelected() {
    if (this.skipDateTimeSelection) {
      this.currentStep.next('createAppointment');
    } else {
      this.currentStep.next('selectDate');
    }
  }

  onCreateBlocker() {
    this.requestBookingForm.controls.isBlocker.setValue(true);
    if (this.skipDateTimeSelection) {
      this.currentStep.next('createBlocker');
    } else {
      this.currentStep.next('selectDate');
    }
  }

  onDateSelected() {
    if (this.requestBookingForm.controls.isBlocker.value) {
      this.currentStep.next('createBlocker');
    } else {
      this.currentStep.next('createAppointment');
    }
  }

  onChangeDate() {
    this.currentStep.next('selectDate');
  }

  onChangeClient() {
    this.currentStep.next('selectClient');
  }

  onCancel() {
    this.closeModal();
  }

  submitForm() {
    const appointment = this.extractAppointment();

    if (this.editingAppointment) {
      this.setBusy(true);
      this.cdr.detectChanges();
      this.calendarService.updateAppointment(appointment).subscribe(
        (res) => {
          if (res.status === 'error') {
            this.setBusy(false);
            if (res.message) {
              this.alertService.error(res.message);
            } else {
              this.alertService.error(
                'booking.alerts.appointment_update_error'
              );
            }
          } else {
            this.alertService.success('booking.alerts.appointment_updated');
            this.appointmentConfigured.emit(appointment);
            this.closeModal();
          }
        },
        (httpErrorResponse) => {
          MonitoringService.captureException(httpErrorResponse.error);
          this.setBusy(false);
          this.handleHttpErrorResponse(
            httpErrorResponse,
            'booking.alerts.appointment_update_error'
          );
        }
      );
    } else {
      this.createAppointment(appointment);
    }
  }

  createAppointment(appointment: Partial<Appointment>) {
    this.setBusy(true);

    if (appointment.status === 'blocker') {
      this.addBlocker(appointment);
      return;
    }
    // Currently all appointments require a message in the conversation to be attached to
    // Note, not a big fan of nested subscribers here. This should really be one observable in the future.
    this.messageService.getConversation(appointment.client.id).subscribe(
      (conv: any) => {
        if (!conv || !conv.data || !conv.data.attributes) {
          MonitoringService.captureMessage("Couldn't find conversation", {
            extra: { appointment },
          });
          this.setBusy(false);
          this.alertService.error('booking.alerts.appointment_create_error');
          return;
        }

        const msg = new Message();
        msg.appointment = appointment;
        const conversation = conv.data.attributes;
        this.messageService.sendMessage(msg, conversation.id).subscribe(
          (res) => {
            this.alertService.success('booking.alerts.appointment_created');
            this.sharedService.track('appointment_requested');
            this.appointmentConfigured.emit(appointment);
            this.closeModal();
          },
          (httpErrorResponse) => {
            MonitoringService.captureException(httpErrorResponse.error, {
              extra: { appointment },
            });
            this.setBusy(false);
            this.handleHttpErrorResponse(
              httpErrorResponse,
              'booking.alerts.appointment_create_error'
            );
          }
        );
      },
      (error) => {
        MonitoringService.captureException(error, { extra: { appointment } });
        this.setBusy(false);
        this.alertService.error('booking.alerts.appointment_create_error');
        return;
      }
    );
  }

  private setBusy(isBusy: boolean) {
    this.isBusy = isBusy;
    this.cdr.detectChanges();
  }

  addBlocker(blockerAppointment) {
    this.calendarService
      .saveAppointment(blockerAppointment)
      .subscribe((res) => {
        this.alertService.success('Blocked date!');
        this.appointmentConfigured.emit(blockerAppointment);
        this.closeModal();
      });
  }

  private extractAppointment() {
    const formValues = this.requestBookingForm.value;
    const startTime = formValues.appointmentDate;
    const startTimetasmpInUTC = this.sharedService
      .adjustProfileTimezoneDateToUTC(startTime)
      .getTime();
    const endDateInUTC = formValues.isFullDay
      ? this.sharedService.adjustProfileTimezoneDateToUTC(
          moment(startTime).endOf('day').toDate()
        )
      : new Date(startTimetasmpInUTC + formValues.duration * 60000);

    const hasAppointmentSlotChanged =
      !this.editingAppointment ||
      this.editingAppointment.start.getTime() !== startTimetasmpInUTC ||
      this.editingAppointment.end.getTime() !== endDateInUTC.getTime();

    const previousAppointment = this.editingAppointment
      ? {
          ...this.editingAppointment,
          // inquires are upgraded to pending appointments now that they have been configured by the therapist
          status: formValues.isBlocker
            ? 'blocker'
            : this.editingAppointment.status === 'inquiry'
            ? hasAppointmentSlotChanged || formValues.paymentType === 'prepay'
              ? 'pending'
              : 'accepted'
            : this.editingAppointment.status,
        }
      : {
          status: formValues.isBlocker ? 'blocker' : 'pending',
        };

    const appointment: Partial<Appointment> = {
      ...previousAppointment,
      is_online: formValues.isOnline,
      is_couples_session: formValues.isCouplesSession,
      start: new Date(startTimetasmpInUTC),
      end: endDateInUTC,
      payment_type: formValues.paymentType,
      prepay_price: formValues.prepayPrice,
      note: formValues.note,
      client: formValues.client,
      profile: this.profile,
    };
    return appointment;
  }

  closeModal() {
    this.isShowingModal = false;
    this.isReady = false;
    this.requestBookingForm = null;
    this.editingAppointment = undefined;
    this.setBusy(false);
  }

  private isMinimumPriceValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return control.get('paymentType').value === PaymentType.prepay &&
      !control.get('isBlocker').value &&
      control.get('prepayPrice').value < 5
      ? { priceTooLow: true }
      : null;
  };

  private durationValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const valid = control.value >= 1 && control.value <= 999;
    return valid ? null : { durationInvalid: { value: control.value } };
  };

  private handleHttpErrorResponse(
    httpErrorResponse: any,
    genericErrorKey: string
  ): void {
    let errorMessage = genericErrorKey; // Default error message

    if (httpErrorResponse.error) {
      if (typeof httpErrorResponse.error.error === 'string') {
        // If there's a direct error message, use it
        errorMessage = httpErrorResponse.error.error;
      } else if (
        Array.isArray(httpErrorResponse.error.errors) &&
        httpErrorResponse.error.errors.length > 0
      ) {
        // If there are specific error messages in an array, use the first one
        errorMessage = httpErrorResponse.error.errors[0];
      } else if (typeof httpErrorResponse.error.message === 'string') {
        // If there's a general error message, use it
        errorMessage = httpErrorResponse.error.message;
      }
    }

    this.alertService.error(errorMessage);
  }
}
