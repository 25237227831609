<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
  *ngIf="currentUser"
>
  <app-therapist-onboarding-eventually-approve-box
    *ngIf="currentUser.type == 'Therapist' && isEventuallyApproveEnabled"
  ></app-therapist-onboarding-eventually-approve-box>
  <app-onboarding-box
    *ngIf="currentUser.type == 'Therapist' && !isEventuallyApproveEnabled"
  ></app-onboarding-box>
  <app-client-onboarding-box
    *ngIf="currentUser.type == 'Client'"
  ></app-client-onboarding-box>

  <!-- Mobile view of photo and title -->
  <div *ngIf="currentUser" class="home-profile-header is-hidden-desktop">
    <div class="upload-photo-container" *ngIf="!uploadPhoto">
      <app-profile-picture
        [variant]="'thumbnail'"
        [profile_id]="currentUser.profile.id"
      ></app-profile-picture>
      <button
        (click)="uploadPhoto = true"
        *ngIf="
          currentUser.type == 'Client' &&
          currentUser.profile &&
          currentUser.profile.images?.length == 0
        "
        class="button is-outlined is-rounded is-small"
      >
        Upload Picture
      </button>
    </div>

    <app-photo-uploader
      *ngIf="uploadPhoto"
      (closeModal)="uploadPhoto = false"
      [dropZoneClass]="'icdropzone'"
      [currentUser]="currentUser"
      [profile]="currentUser.profile"
      [showModal]="uploadPhoto"
    ></app-photo-uploader>

    <div class="home-profile-header-details" *ngIf="!uploadPhoto">
      <h2 class="home-profile-header-title" *ngIf="currentUser.profile">
        {{ currentUser.profile.firstname + " " + currentUser.profile.lastname }}
      </h2>
    </div>
  </div>

  <div class="columns is-desktop columns-responsive is-marginless">
    <div class="column is-3 is-hidden-touch">
      <app-sidenav></app-sidenav>
    </div>

    <main class="column is-9">
      <ng-container *ngIf="currentUser?.type == 'Therapist'">
        <section
          class="dashboard-teaser dashboard-quick-actions is-hidden-desktop"
          *ngIf="currentUser.profile.profile_approved"
        >
          <header class="dashboard-teaser-header">
            <h3 class="dashboard-teaser-title">Quick Actions</h3>
          </header>
          <div class="card">
            <div class="card-content content">
              <a routerLink="/home/profile" class="button is-rounded">
                <span class="icon is-small">
                  <span class="icon icon-edit-primary"></span>
                </span>
                <span>{{ "navigation.edit_profile" | translate }}</span>
              </a>
              <a (click)="showInviteClientsModal()" class="button is-rounded">
                <span class="icon is-small">
                  <span class="icon icon-invite-client-primary"></span>
                </span>
                <span>{{ "navigation.invite_clients" | translate }}</span>
              </a>
              <ng-container
                class="control"
                *ngIf="
                  currentUser.profile.stripe_connect_active == true;
                  else elseBlock
                "
              >
                <a
                  class="button is-rounded"
                  title="Create Invoice"
                  *ngIf="
                    currentUser &&
                    currentUser.type == 'Therapist' &&
                    currentUser.profile.stripe_connect_active == true
                  "
                  (click)="createNewInvoice()"
                >
                  <span class="icon is-small">
                    <span class="icon icon-note-primary"></span>
                  </span>
                  <span>{{ "office.actions.create_invoice" | translate }}</span>
                </a>
              </ng-container>
              <ng-template #elseBlock>
                <a routerLink="/office/settings" class="button is-rounded">
                  <span class="icon is-small">
                    <span class="icon icon-edit-primary"></span>
                  </span>
                  <span>{{ "registration.register-here" | translate }}</span>
                </a>
              </ng-template>
            </div>
          </div>
        </section>

        <shared-announcements
          [currentUser]="currentUser"
          *ngIf="currentUser"
        ></shared-announcements>
        <!-- <home-feature-promotion-boxes [currentUser]="currentUser" *ngIf="currentUser.type == 'Therapist'"></home-feature-promotion-boxes> -->
        <app-status-update-box
          [currentUser]="currentUser"
        ></app-status-update-box>
        <app-appointments-requiring-completion
          [currentUser]="currentUser"
        ></app-appointments-requiring-completion>
        <app-invoice-statistics-box
          *ngIf="
            this.profile.country &&
            this.env.stripe_available_countries.includes(this.profile.country)
          "
          [profile]="currentUser.profile"
        ></app-invoice-statistics-box>
        <app-latest-messages
          *ngIf="!isUsingGetstreamChat()"
          [showFakeTemplate]="true"
          [truncateSize]="40"
          [conversations]="conversations"
          [showViewAll]="true"
        ></app-latest-messages>
        <app-latest-messages-v2
          *ngIf="isUsingGetstreamChat()"
          [truncateSize]="40"
          [showViewAll]="true"/>
        <app-next-appointment
          [currentUser]="currentUser"
        ></app-next-appointment>
        <app-calendar-overview></app-calendar-overview>
        <app-statistics [user]="currentUser"></app-statistics>
      </ng-container>

      <ng-container *ngIf="currentUser.type == 'Client'">
        <shared-announcements
          [currentUser]="currentUser"
          *ngIf="currentUser"
        ></shared-announcements>
        <app-latest-messages
          [showFakeTemplate]="true"
          [truncateSize]="40"
          [showViewAll]="true"
          [conversations]="conversations"
        ></app-latest-messages>
        <app-latest-messages-v2
          *ngIf="isUsingGetstreamChat()"
          [truncateSize]="40"
          [showViewAll]="true"/>
        <app-next-appointment
          [currentUser]="currentUser"
        ></app-next-appointment>
        <app-calendar-overview></app-calendar-overview>
      </ng-container>
    </main>
  </div>
</div>
<app-footer *ngIf="currentUser && currentUser.id"></app-footer>
