<div class="card">
  <div class="card-content">
    <div class="columns profile-columns">
      <div class="picture-and-availability">
        <app-profile-picture
          [profile_id]="therapist.profile.id"
          [variant]="'profile'"
        ></app-profile-picture>
        <div class="has-text-centered mt-2">
          <span
            [ngClass]="
              therapist.profile.is_available
                ? 'available-dot'
                : 'unavailable-dot'
            "
            class="status-dot"
          ></span>
          <span class="availability">
            {{
              (therapist.profile.is_available
                ? "my-favorite-therapists.available"
                : "my-favorite-therapists.unavailable"
              ) | translate
            }}
          </span>
        </div>
      </div>
      <div class="profile-text-content">
        <div class="name-and-favorite">
          <h4 class="therapist-name">{{ therapist.name }}</h4>
          <div
            class="favorite-action"
            (click)="$event.stopPropagation(); toggleFavorite(therapist)"
          >
            <div class="favorite-action-icon">
              <svg
                *ngIf="isFavorite; else notFavoriteIcon"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.35248 4.90532C1.35248 2.94498 2.936 1.35248 4.89346 1.35248C6.25769 1.35248 6.86058 1.92336 7.50002 2.93545C8.13946 1.92336 8.74235 1.35248 10.1066 1.35248C12.064 1.35248 13.6476 2.94498 13.6476 4.90532C13.6476 6.74041 12.6013 8.50508 11.4008 9.96927C10.2636 11.3562 8.92194 12.5508 8.00601 13.3664C7.94645 13.4194 7.88869 13.4709 7.83291 13.5206C7.64324 13.6899 7.3568 13.6899 7.16713 13.5206C7.11135 13.4709 7.05359 13.4194 6.99403 13.3664C6.0781 12.5508 4.73641 11.3562 3.59926 9.96927C2.39872 8.50508 1.35248 6.74041 1.35248 4.90532Z"
                  fill="#4cc2ac"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <ng-template #notFavoriteIcon>
                <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.89346 2.35248C3.49195 2.35248 2.35248 3.49359 2.35248 4.90532C2.35248 6.38164 3.20954 7.9168 4.37255 9.33522C5.39396 10.581 6.59464 11.6702 7.50002 12.4778C8.4054 11.6702 9.60608 10.581 10.6275 9.33522C11.7905 7.9168 12.6476 6.38164 12.6476 4.90532C12.6476 3.49359 11.5081 2.35248 10.1066 2.35248C9.27059 2.35248 8.81894 2.64323 8.5397 2.95843C8.27877 3.25295 8.14623 3.58566 8.02501 3.88993C8.00391 3.9429 7.98315 3.99501 7.96211 4.04591C7.88482 4.23294 7.7024 4.35494 7.50002 4.35494C7.29765 4.35494 7.11523 4.23295 7.03793 4.04592C7.01689 3.99501 6.99612 3.94289 6.97502 3.8899C6.8538 3.58564 6.72126 3.25294 6.46034 2.95843C6.18109 2.64323 5.72945 2.35248 4.89346 2.35248ZM1.35248 4.90532C1.35248 2.94498 2.936 1.35248 4.89346 1.35248C6.0084 1.35248 6.73504 1.76049 7.20884 2.2953C7.32062 2.42147 7.41686 2.55382 7.50002 2.68545C7.58318 2.55382 7.67941 2.42147 7.79119 2.2953C8.265 1.76049 8.99164 1.35248 10.1066 1.35248C12.064 1.35248 13.6476 2.94498 13.6476 4.90532C13.6476 6.74041 12.6013 8.50508 11.4008 9.96927C10.2636 11.3562 8.92194 12.5508 8.00601 13.3664C7.94645 13.4194 7.88869 13.4709 7.83291 13.5206C7.64324 13.6899 7.3568 13.6899 7.16713 13.5206C7.11135 13.4709 7.05359 13.4194 6.99403 13.3664C6.0781 12.5508 4.73641 11.3562 3.59926 9.96927C2.39872 8.50508 1.35248 6.74041 1.35248 4.90532Z"
                    fill="#4cc2ac"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </ng-template>
            </div>
          </div>
        </div>

        <p class="therapist-jobtitle">{{ therapist.profile.jobtitle }}</p>
        <div class="is-flex-grow-1">
          <p class="professional-statement">
            {{ therapist.profile.professional_statement }}
          </p>
        </div>
        <div class="price-and-location">
          <div
            *ngIf="
              therapist.profile.showPrice && therapist.profile.price_per_session
            "
            class="price"
          >
            <span class="price-value">
              {{
                therapist.profile.price_per_session
                  | currency : (therapist.profile.currency | uppercase)
              }}</span
            >
            <span class="price-suffix">/session</span>
          </div>
          <span class="location">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="21"
              viewBox="0 0 23 21"
            >
              <path
                fill="#4CC2AC"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 21C14.5 21 20 12.2242 20 7.875C20 3.52576 16.4183 0 12 0C7.58172 0 4 3.52576 4 7.875C4 12.2242 9.5 21 12 21ZM12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
              />
            </svg>
            <span>{{ therapist.profile.city.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
