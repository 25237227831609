import { User } from '../../../entities/user.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { NgClass, NgIf } from '@angular/common';
import { VideoService } from 'src/app/shared/video/video.service';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { PaymentType } from '../../../entities/PaymentType';
import { createNewWaitingRoomLink } from '../../../shared/helpers/navigation_helper';
import { TranslateModule } from '@ngx-translate/core';
import { MatchedByIcComponent } from '../../../shared/components/badges/matched-by-ic/matched-by-ic.component';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { AvatarImageComponent } from '../../../shared/components/avatar-image/avatar-image.component';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';
import { getProfileImageUrl } from '../../../shared/helpers/profile-image-helper';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-conversation-header',
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    ProfilePictureComponent,
    NgClass,
    TranslateModule,
    MatchedByIcComponent,
    AvatarImageComponent,
    MatTooltip,
    MatIcon,
  ],
})
export class ConversationHeaderComponent implements OnInit {
  protected readonly PaymentType = PaymentType;
  @Input() otherUserOnlineStatus: boolean = false;
  @Input() currentUser?: User;
  @Input() otherUser: LeanConversationUser;
  @Input() showMatchedBadge: boolean = false;
  @Input() conversationIsHidden: boolean = false;
  @Output() reportOtherUser = new EventEmitter<void>();
  @Output() hideConversation = new EventEmitter<void>();
  @Output() unhideConversation = new EventEmitter<void>();
  public showUserContextMenu: boolean = false;

  constructor(
    private videoService: VideoService,
    private analytics: AmplitudeAnalyticsService
  ) {}

  ngOnInit() {}

  showOtherUser() {
    this.showUserContextMenu = false;
    window.open(this.otherUser.url);
  }

  private getTherapistSlug() {
    return this.currentUser.type === 'Therapist'
      ? this.currentUser.profile.slug
      : this.otherUser.slug;
  }

  startVideo() {
    this.analytics.trackVideoCallInitiated({
      source_page: 'messages_page',
      kind: 'instant_private_call',
    });
    this.initiateGetstreamCall();
  }

  openWaitingRoomLink() {
    const waitingRoomLink = createNewWaitingRoomLink(this.getTherapistSlug());
    window.open(waitingRoomLink, '_blank');
  }

  private initiateGetstreamCall() {
    this.videoService.navigateToGetstreamVideoCall(
      this.videoService.generateAdHocWithClientCallSlug(
        this.currentUser.profile.id,
        this.otherUser.id
      )
    );
  }

  reportUser() {
    this.reportOtherUser.emit();
    this.showUserContextMenu = false;
  }

  onHideConversation() {
    this.hideConversation.emit();
    this.showUserContextMenu = false;
  }

  onUnhideConversation() {
    this.unhideConversation.emit();
    this.showUserContextMenu = false;
  }

  protected readonly getProfileImageUrl = getProfileImageUrl;
}
