<div class="columns is-vcentered" *ngIf="logType">
  <div class="column">
    <div class="columns is-mobile" style="margin: 0">
      <ng-container *ngIf="logType == 'online-session'">
        <div class="column is-narrow">
          <i class="icon icon-video" style="margin: 0.5rem"></i>
        </div>
        <div class="column" style="padding-right: 0">
          <div class="attachment-title">
            <h3>
              <span>{{ "messaging.log-attachment.title" | translate }}</span>
            </h3>
            {{ "messaging.log-attachment.started" | translate
            }}{{ log.created_at | date : "shortTime" }} |
            {{ "messaging.log-attachment.lasted" | translate
            }}<span *ngIf="log.duration >= 3600"
              >{{ log.duration * 1000 | date : "H" : "UTC" }} h
            </span>
            <span *ngIf="log.duration >= 60"
              >{{ log.duration * 1000 | date : "m" : "UTC" }}
              {{ "common.min" | translate }}
            </span>
            <span *ngIf="log.duration < 3600"
              >{{ log.duration * 1000 | date : "s" : "UTC" }}
              {{ "common.sec" | translate }}</span
            >
          </div>
          <div
            class="actions"
            *ngIf="currentUser.type == 'Therapist' && areDetailsLoaded"
          >
            <button
              *ngIf="!invoice && canUseInvoicing"
              [ngClass]="{ 'is-loading': isSavingInvoice }"
              class="button is-rounded is-small"
              (click)="invoiceSession()"
              [disabled]="isSavingInvoice"
            >
              {{ "messaging.log-attachment.invoice-open" | translate }}
            </button>
          </div>
          <span *ngIf="invoice">{{
            "messaging.log-attachment.invoice-done" | translate
          }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="logType == 'clientprofile_access'">
        <div class="column">
          <p>
            <em>{{
              "messaging.log-attachment." +
                currentUser.type.toLowerCase() +
                "." +
                log.access | translate : { name: otherUser.name.split(" ")[0] }
            }}</em>
          </p>
          <div class="actions" *ngIf="currentUser.type == 'Client'">
            <button
              class="button is-rounded is-small"
              [routerLink]="['/home/settings/profile']"
            >
              {{ "messaging.log-attachment.manage_access" | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
