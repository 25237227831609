import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'first-inquiry-sent-dialog',
  templateUrl: 'first-inquiry-sent-dialog.html',
  styleUrls: ['./first-inquiry-sent-dialog.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
})
export class FirstInquirySentDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { therapistName: string; directoryLink: string }
  ) {}

  onReturnToDirectory() {
    window.location.href = this.data.directoryLink;
  }
}
