<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
>
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch is-marginless">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column message-col">
      <div class="container-fluid container-fluid-limited">
        <ngx-file-drop
          dropZoneClassName="filedropzone"
          contentClassName="filedropcontent"
          multiple="false"
          (onFileDrop)="onFileDropped($event)"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="fileattachment"
          >
            <div
              id="ic-messages-container"
              class="card has-border"
              *ngIf="!isLoading"
            >
              <div class="header-holder">
                <app-conversation-header
                  *ngIf="otherUser"
                  [otherUserOnlineStatus]="otherUserOnlineStatus"
                  [currentUser]="currentUser"
                  [otherUser]="otherUser"
                  [showMatchedBadge]="currentUser && currentUser.type == 'Therapist' && conversation.is_result_of_match"
                  [conversationIsHidden]="conversation.archived"
                  (reportOtherUser)="wantsToReportUser = true"
                  (hideConversation)="archiveConversation(conversation.id, true)"
                  (unhideConversation)="archiveConversation(conversation.id, false)"/>
              </div>

              <div
                class="connection-issues"
                *ngIf="isConnected === false && isOnline"
              >
                {{ "messaging.server-connection-issues" | translate }}
              </div>
              <div class="connection-issues" *ngIf="isOnline === false">
                {{ "messaging.connection-issues" | translate }}
              </div>
              <app-eap-client-banner [currentUser]="currentUser" [otherUser]="otherUser"></app-eap-client-banner>
              <app-eap-not-allowed-banner [currentUser]="currentUser"></app-eap-not-allowed-banner>

              <div class="card no-shadow messages" #messages>
                <ng-container
                  *ngIf="
                    conversation &&
                    currentUser &&
                    otherUser &&
                    otherUser.flagged
                  "
                  class="card-content"
                >
                  <div class="notification">
                    {{ "messaging.flagged-text" | translate }}
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    conversation &&
                    currentUser &&
                    otherUser &&
                    !otherUser.flagged
                  "
                  class="card-content"
                >
                  <!--                  <div-->
                  <!--                    *ngIf="-->
                  <!--                      therapistConnection &&-->
                  <!--                      !therapistConnection.has_client_profile_access &&-->
                  <!--                      env.name != 'production'-->
                  <!--                    "-->
                  <!--                    class="access-request notification is-primary"-->
                  <!--                  >-->
                  <!--                    <span-->
                  <!--                    >🧪&nbsp;&nbsp;{{-->
                  <!--                        "messaging.log-attachment.access_1" | translate-->
                  <!--                      }}-->
                  <!--                      {{ otherUser.name }}-->
                  <!--                      {{-->
                  <!--                        "messaging.log-attachment.access_2" | translate-->
                  <!--                      }}</span-->
                  <!--                    >-->
                  <!--                    <a-->
                  <!--                      class="button is-rounded is-small"-->
                  <!--                      (click)="shareClientProfile()"-->
                  <!--                    >{{ "messaging.log-attachment.give_acc" | translate }}</a-->
                  <!--                    >-->
                  <!--                  </div>-->

                  <div class="notification">
                    {{ "messaging.all-messages-encrypted" | translate }}
                  </div>

                  <div
                    class="has-text-centered fetchMore"
                    *ngIf="hasMoreMessages"
                  >
                    <button
                      [ngClass]="{ 'is-loading': isLoadingMoreMessages }"
                      (click)="fetchMoreMessages()"
                      class="button is-primary is-rounded"
                    >
                      {{ "messaging.load-older" | translate }}
                    </button>
                  </div>

                  <div
                    #messagesLoop
                    *ngFor="let msg of conversation.messages; let i = index"
                    class="columns message-columns msg-line"
                  >
                    <div
                      *ngIf="!otherUser.flagged"
                      class="column is-9 message-column"
                      [ngClass]="{
                        'is-offset-3': msg.user_id == currentUser.id
                      }"
                    >
                      <div
                        class="chat-message"
                        viewportNotifier
                        (visibleInViewportChange)="
                          onMessageInViewport($event, msg)
                        "
                        [ngClass]="{
                          own: msg.user_id == currentUser.id,
                          'has-file': msg.file_attachment
                        }"
                      >
                        <h4 class="heading has-text-centered">
                          <span
                            class="time"
                            *ngIf="i !== 0 && wasSentYesterday(conversation, i)"
                          >{{ msg.created_at | date : "dd.MM.yyyy" }}</span
                          >
                          <span class="time" *ngIf="i == 0">{{
                              msg.created_at | date : "dd.MM.yyyy"
                            }}</span>
                        </h4>
                        <div class="bubble" *ngIf="msg.decrypted">
                          <a
                            class="message-actions"
                            (click)="showActions(msg, $event)"
                            *ngIf="
                              msg.user_id == currentUser.id && !msg.deleted
                            "
                          >
                            <svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                              />
                            </svg>
                          </a>
                          <p
                            class="message-content"
                            [innerHTML]="msg.body | linky"
                            *ngIf="!msg.deleted && msg.body.length > 0"
                          ></p>
                          <p class="message-content" *ngIf="msg.deleted">
                            <em>{{
                                "messaging.message-deleted" | translate
                              }}</em>
                          </p>
                          <div
                            class="lds-ellipsis"
                            *ngIf="msg.body == 'loading'"
                            style="height: 17px; bottom: 21px"
                          >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span class="message-date">
                            <i
                              *ngIf="msg.user_id == currentUser.id && msg.read"
                              title="Message read"
                              class="icon icon-read"
                            ></i>
                            {{ msg.created_at | date : "shortTime" }}
                          </span>
                          <message-image-attachment
                            *ngIf="msg.file_attachment && !msg.deleted"
                            [message]="msg"
                            (imageClicked)="openImageModal($event)"
                          ></message-image-attachment>
                          <app-booking-attachment
                            *ngIf="msg.appointment && !msg.deleted"
                            [appointment]="msg.appointment"
                            [changeId]="msg.id"
                            [clientId]="msg.appointment.client.id"
                            [profileId]="msg.appointment.profile.id"
                            [canUseVideo]="msg.appointment.can_use_video"
                            [currentUser]="currentUser"
                            [editAppointment]="
                              this.editAppointment.bind(this, msg.appointment)
                            "
                            (changed)="onMessageChanged($event)"
                          ></app-booking-attachment>
                          <app-invoice-attachment
                            *ngIf="msg.invoice && !msg.deleted"
                            [invoice]="msg.invoice"
                            [clientId]="msg.invoice.client.id"
                            [currency]="msg.invoice.profile.currency"
                            [currentUser]="currentUser"
                          ></app-invoice-attachment>
                          <app-log-attachment
                            *ngIf="msg.log && !msg.deleted"
                            [log]="parsedLog(msg.log)"
                            [currentUser]="currentUser"
                            [otherUser]="otherUser"
                          ></app-log-attachment>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="isSupportChat" class="support-ticket notification">
                <span
                >{{ "support.for-technical-issues" | translate }}
                  <a (click)="requestSupport()">{{
                      "support.support-ticket" | translate
                    }}</a></span
                >
              </div>

              <div class="message-drop">
                {{ "messaging.drop-files" | translate }}
              </div>

              <div
                [@simpleFadeAnimation]="'in'"
                *ngIf="selectedMessage"
                class="messageMenu"
              >
                <div class="content">
                  <a class="delete" (click)="selectedMessage = null"></a>
                  <button
                    *ngIf="selectedMessage.user_id == currentUser.id"
                    class="button is-danger is-fullwidth is-rounded"
                    (click)="deleteMessage()"
                  >
                    {{ "messaging.delete-message" | translate }}
                  </button>
                  <!-- <button class="button is-primary is-fullwidth is-rounded" (click)="forwardMessage(selectedMessage)">Send again</button> -->
                </div>
              </div>

              <div
                class="message-input"
                *ngIf="
                  message &&
                  !selectedMessage &&
                  otherUser &&
                  !otherUser.deleted_at &&
                  !otherUser.flagged &&
                  !isSupportChat
                "
              >
                <div
                  class="message-preview"
                  *ngIf="message.attachment || message.appointment"
                >
                  <span class="delete" (click)="removeIntent()">X</span>
                  <div class="preview-text" *ngIf="message.attachment">
                    <strong>{{ "messaging.file" | translate }}: </strong>
                    {{ message.attachment.name }}
                  </div>
                  <div class="preview-text" *ngIf="message.appointment">
                    <strong>
                      <span *ngIf="message.appointment.is_online"
                      >{{ "messaging.online" | translate }} </span
                      >{{ "messaging.appointment" | translate }}:
                    </strong>
                    {{
                      message.appointment.timestamp
                        | date : "dd.MM.yyyy | HH:mm"
                    }}
                    <small *ngIf="showTimezone"
                    >({{ currentUser.profile.timezone }})</small
                    >
                    <small
                      *ngIf="
                        message.appointment.payment_type == PaymentType.prepay
                      "
                    >
                      – {{ "messaging.booking-modal.prepay" | translate }}
                      {{
                        message.appointment.price
                          | currency
                          : (currentUser.profile.currency | uppercase)
                      }}</small
                    >
                  </div>
                </div>
                <div
                  class="input-actions"
                  [ngClass]="{ 'is-excluded': message.body != '' }"
                >
                  <button
                    title="Suggest Appointment"
                    id="calendarButton"
                    class="button is-text is-icon"
                    (click)="openBookingModal()"
                    *ngIf="currentUser?.abilities?.can_create_appointments"
                  >
                    <i class="icon icon-calendar"></i>
                  </button>
                  <button
                    title="Attach File"
                    class="button is-text is-icon"
                    (click)="fileattachment.click()"
                  >
                    <i class="icon icon-attachment"></i>
                  </button>
                  <button
                    title="Create Invoice"
                    *ngIf="
                      currentUser &&
                      currentUser.type == 'Therapist' &&
                      currentUser.profile.stripe_connect_active == true
                    "
                    class="button is-text is-icon is-hidden-touch"
                    (click)="createNewInvoice()"
                  >
                    <i class="icon icon-invoice"></i>
                  </button>
                  <button
                    id="msgSettingsButton"
                    class="button is-text is-icon is-hidden-desktop"
                    (click)="toggleMessageSettings()"
                  >
                    <i class="icon icon-settings"></i>
                  </button>
                </div>
                <textarea
                  #messageInput
                  class="textarea"
                  (keydown)="onKeydown($event)"
                  [(ngModel)]="message.body"
                  placeholder="Message"
                  autosize
                  [minRows]="1"
                ></textarea>
                <button
                  class="button is-primary is-rounded"
                  [ngClass]="{ 'is-loading': sendingMessage }"
                  (click)="sendMessage()"
                  [disabled]="
                    sendingMessage ||
                    ((!message.body || message.body == '') &&
                      !message.attachment &&
                      !message.appointment)
                  "
                >
                  <i
                    class="icon icon-send is-hidden-desktop is-hidden-tablet"
                  ></i>
                  <span class="is-hidden-touch">{{
                      "messaging.send" | translate
                    }}</span>
                </button>
                <button
                  id="msgSettingsButton"
                  class="button is-text is-icon is-hidden-touch"
                  (click)="toggleMessageSettings()"
                >
                  <i class="icon icon-settings"></i>
                </button>
                <input
                  id="file-input"
                  [attr.data-direct-upload-url]="
                    env.railsBaseUrl + '/rails/active_storage/direct_uploads'
                  "
                  type="file"
                  name="name"
                  (change)="setFile($event.target.files)"
                  #fileattachment
                  style="display: none"
                />

                <div class="message-settings" *ngIf="showMessageSettings">
                  <span>&nbsp;{{ "messaging.send-on-enter" | translate }}</span>
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="sendOnEnter"/>
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div class="upload-progress" *ngIf="uploading">
                {{ uploadProgress }} %
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </div>
  </div>

  <div
    class="modal"
    [ngClass]="{ 'is-active': wantsToReportUser }"
    *ngIf="otherUser"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <a class="delete" (click)="wantsToReportUser = false"></a>
      <h3 class="modal-title">
        {{ "support.report-user" | translate }}: {{ otherUser.name }}
      </h3>
      <p>{{ "support.report-user-text" | translate }}</p>
      <div class="field">
        <label class="label">{{ "support.describe-issue" | translate }}:</label>
        <div class="control">
          <textarea
            class="textarea"
            [(ngModel)]="report.description"
          ></textarea>
        </div>
      </div>
      <p>{{ "support.include-messages" | translate }}</p>
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="report.includeMessages"/>
          {{ "support.include-last-messages" | translate }}
        </label>
      </div>
      <div class="modal-actions">
        <button class="button is-rounded" (click)="wantsToReportUser = false">
          {{ "messaging.cancel" | translate }}
        </button>
        <button
          class="button is-rounded is-danger"
          (click)="reportUser()"
          [ngClass]="{ 'is-loading': isReportingUser }"
        >
          {{ "support.report-user" | translate }}
        </button>
      </div>
    </div>
  </div>

  <app-booking-form-container
    *ngIf="currentUser && currentUser.type == 'Therapist'"
    [currentUser]="currentUser"
    #bookingModal
  ></app-booking-form-container>
  <app-note-modal
    *ngIf="showNoteModal"
    [conversation]="conversation"
    [client_id]="otherUser.id"
    (modalState)="onNoteModalStateChanged($event)"
  ></app-note-modal>
  <app-image-modal
    *ngIf="selectedImageMessage"
    [message]="selectedImageMessage"
    (modalState)="onImageModalStateChanged($event)"
  ></app-image-modal>
  <app-payment-modal></app-payment-modal>
</div>
