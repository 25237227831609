import { HomeComponent } from './../../components/home/home.component';
import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../shared/base-class';
import { ICAuthService } from '../../auth/service/i-c-auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { RegisterComponent } from 'src/app/auth/register/register.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ProfileComponent } from 'src/app/components/profile/profile.component';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { InviteClientsService } from 'src/app/shared/components/invite-clients/service/invite-clients.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { AmplitudeAnalyticsService } from '../../shared/services/analytics/amplitude-analytics.service';
import {
  createDirectoryLink,
  createEditProfileLink,
  createProfileLink,
} from '../../shared/helpers/navigation_helper';
import { TranslateModule } from '@ngx-translate/core';
import { SupportModalComponent } from '../../shared/components/support-modal/support-modal.component';
import { NewNoteModalComponent } from '../../shared/components/new-note-modal/new-note-modal.component';
import { WaitingRoomAnnouncementComponent } from '../../shared/components/waiting-room-announcement/waiting-room-announcement.component';
import { PayoutModalComponent } from '../../shared/components/office/payout-modal/payout-modal.component';
import { InvoiceCreateModalComponent } from '../../office/invoice-create-modal/invoice-create-modal.component';
import { InviteClientsComponent } from '../../shared/components/invite-clients/invite-clients.component';
import { ProfilePictureComponent } from '../../shared/components/profile-picture/profile-picture.component';
import { InvoiceAmountComponent } from '../../office/invoice-amount/invoice-amount.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    InvoiceAmountComponent,
    RouterLink,
    ProfilePictureComponent,
    RouterLinkActive,
    InviteClientsComponent,
    InvoiceCreateModalComponent,
    PayoutModalComponent,
    WaitingRoomAnnouncementComponent,
    NewNoteModalComponent,
    SupportModalComponent,
    TranslateModule,
  ],
})
export class HeaderComponent extends BaseClass implements OnInit {
  constructor(
    private sharedService: SharedService,
    private authService: ICAuthService,
    private router: Router,
    private alertService: AlertService,
    private notificationService: NotificationsService,
    private inviteClientsService: InviteClientsService,
    private analytics: AmplitudeAnalyticsService
  ) {
    super(sharedService, alertService, router);
  }

  public showDropDown: boolean = false;
  public showStepsBoolean: boolean;
  public likes: number;
  public unreadMessagesCount: number;
  public env = environment;
  public myOfficeAvailable: boolean = false;
  public shouldShowSubscriptionErrorBox: boolean = false;
  public lang: string;

  logout() {
    this.authService.signOut();
    this.toggleDropDown();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToStepsState();

    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.lang = user.locale;
        this.myOfficeAvailable = this.env.stripe_available_countries.includes(
          this.currentUser.profile.country
        );
        this.validateSubscription();
      }
    });

    this.notificationService.unreadMessagesCount.subscribe(
      (count) => (this.unreadMessagesCount = count)
    );
  }

  validateSubscription() {
    this.sharedService.validateSubscription().subscribe((response) => {
      this.shouldShowSubscriptionErrorBox = response.status;
    });
  }

  //Subscribe to notifications from messages

  //Based on wheter client or therapist at component, show/hide Steps

  subscribeToStepsState() {
    this.sharedService.showStepsComponent.subscribe((bool) => {
      bool == true || bool == null
        ? (this.showStepsBoolean = true)
        : (this.showStepsBoolean = false);
    });
  }

  showSteps(): boolean {
    if (this.currentUser && this.currentUser.type == 'Client') {
      return false;
    } else if (this.getComponent() == 'RegisterComponent') {
      return true;
    } else if (
      (this.getComponent() == 'ProfileComponent' &&
        this.currentUser.profile &&
        !this.currentUser.profile.profile_approved) ||
      (this.getComponent() == 'ProfileComponent' &&
        this.currentUser.profile &&
        this.currentUser.profile.profile_approved == false)
    ) {
      return true;
    } else return false;
  }

  openCommunity() {
    this.analytics.trackOpenedCommunity({
      source_page: 'dashboard',
      source_action: 'header_navigation',
    });
    window.open('https://community.complicated.life', '_blank');
  }

  toggleDropDown = () => {
    this.showDropDown = !this.showDropDown;
  };

  showInviteClientsModal() {
    this.toggleDropDown();
    this.inviteClientsService.openModal();
  }

  homelink() {
    var route = this.router.url;
    if (
      route == '/home' ||
      route == '/sign-up' ||
      route == '/register' ||
      route == '/login'
    ) {
      this.showDirectory();
    } else {
      this.router.navigate(['/home']);
    }
  }

  showDirectory() {
    window.location.href = createDirectoryLink(this.lang);
  }

  openEditProfileV2() {
    window.location.href = createEditProfileLink(this.currentUser.locale);
  }

  viewProfile() {
    window.open(createProfileLink(this.currentUser, this.lang), '_blank');
  }

  getComponent(): string {
    if (this.currentComponent instanceof LoginComponent)
      return 'LoginComponent';
    else if (this.currentComponent instanceof RegisterComponent)
      return 'RegisterComponent';
    else if (this.currentComponent instanceof RegisterComponent)
      return 'RegisterComponent';
    else if (this.currentComponent instanceof ProfileComponent)
      return 'ProfileComponent';
    else if (this.currentComponent instanceof HomeComponent)
      return 'HomeComponent';
  }

  componentIsntAuth(): boolean {
    if (this.currentComponent instanceof LoginComponent) return false;
    else if (this.currentComponent instanceof RegisterComponent) return false;
    else return true;
  }
}
