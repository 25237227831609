<nav class="header">
  <div
    class="back navitem"
    [routerLink]="['/home/messaging/']"
  ></div>
  <app-avatar-image
    class="avatar-image otherUserStatus"
    [loading]="!otherUser"
    [ngClass]="{
                    online: otherUserOnlineStatus,
                    offline: !otherUserOnlineStatus
                  }"
    [src]="getProfileImageUrl(
          this.otherUser?.profile_id,
          'mini'
        )"
    [alt]="'Profile Picture'"
    [showNotification]="false"
    [showMatchedBadge]="false"
  ></app-avatar-image>
  <div *ngIf="otherUser?.flagged">
    <h2 class="chatpartner-name has-margin-left-5">
      {{ "messaging.flagged-user" | translate }}
    </h2>
  </div>
  <div
    *ngIf="otherUser"
    class="navbar-item has-dropdown"
    [ngClass]="{ 'is-active': showUserContextMenu}"
  >
    <a
      class="navbar-link"
      (click)="showUserContextMenu = !showUserContextMenu"
    >
      <div>
        <h2 class="chatpartner-name">
          {{ otherUser.name }}
        </h2>
        <ng-container *ngIf="otherUser.jobTitle">
          <h3 class="chatpartner-title">
            {{ otherUser.jobTitle }}
          </h3>
        </ng-container>
      </div>
    </a>
    <div class="navbar-dropdown" *ngIf="otherUser && !otherUser?.flagged">
      <a class="navbar-item" (click)="showOtherUser()">
        {{ "common.show" | translate }}
        <span *ngIf="otherUser.type == 'Client'"
        >&nbsp;{{ "common.client" | translate }}
                      </span>
        <span *ngIf="otherUser.type == 'Therapist'"
        >&nbsp;{{ "common.profile" | translate }}
                      </span>
      </a>
      <a
        class="navbar-item"
        *ngIf="currentUser && currentUser.type == 'Therapist'"
        (click)="reportUser()"
      >
        {{ "support.report-user" | translate }}
      </a>
      <a
        class="navbar-item"
        *ngIf="currentUser && currentUser.type == 'Therapist' && !conversationIsHidden"
        (click)="onHideConversation()"
      >
        <span
          matTooltipClass="mat-tooltip"
          matTooltip="{{'messaging.hide_conversation_tooltip' | translate}}"
          class="is-flex align-center"
          style="cursor: pointer;"
        >{{ 'messaging.hide_conversation' | translate }}&nbsp;<mat-icon
          class="mat-tooltip-trigger-icon">help_outline</mat-icon>
        </span>
      </a>
      <a
        class="navbar-item"
        *ngIf="currentUser && currentUser.type == 'Therapist' && conversationIsHidden"
        (click)="onUnhideConversation()"
      >
        <span
          matTooltipClass="mat-tooltip"
          matTooltip="{{'messaging.un-hide_conversation_tooltip' | translate}}"
          class="is-flex align-center"
          style="cursor: pointer;"
        >{{ 'messaging.un-hide_conversation' | translate }}&nbsp;<mat-icon
          class="mat-tooltip-trigger-icon">help_outline</mat-icon>
        </span>
      </a>
    </div>
  </div>
  <ng-container *ngIf="showMatchedBadge">
    <app-matched-by-ic/>
  </ng-container>
  <div class="spacer"></div>
  <div
    class="buttons"
    *ngIf="otherUser && !otherUser.flagged"
  >
    <div
      *ngIf="currentUser && currentUser.type == 'Therapist' &&  currentUser?.abilities?.can_start_video_call"
      class="button is-rounded is-primary is-outlined video-call"
      data-tooltip="Start a secure video call"
      (click)="startVideo()"
    >
                    <span class="icon is-small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#FFFFFF"
                      >
                        <g>
                          <path
                            d="M18,10.48V6c0-1.1-0.9-2-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4.48l3.15,3.13 C21.46,16.97,22,16.74,22,16.3V7.7c0-0.44-0.54-0.67-0.85-0.35L18,10.48z M10,8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S8.9,8,10,8z M14,16H6v-0.57c0-0.81,0.48-1.53,1.22-1.85C8.07,13.21,9.01,13,10,13c0.99,0,1.93,0.21,2.78,0.58C13.52,13.9,14,14.62,14,15.43V16 z"
                          />
                        </g>
                      </svg>
                    </span>
      <span class="is-hidden-touch">{{
          "messaging.video-call" | translate
        }}</span>
    </div>
    <div
      *ngIf="currentUser && currentUser.type == 'Client' "
      class="button is-rounded is-primary is-outlined video-call"
      data-tooltip="Enter the waiting room for this therapist"
      (click)="openWaitingRoomLink()"
    >
                    <span class="icon is-small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#FFFFFF"
                      >
                        <g>
                          <path
                            d="M18,10.48V6c0-1.1-0.9-2-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4.48l3.15,3.13 C21.46,16.97,22,16.74,22,16.3V7.7c0-0.44-0.54-0.67-0.85-0.35L18,10.48z M10,8c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S8.9,8,10,8z M14,16H6v-0.57c0-0.81,0.48-1.53,1.22-1.85C8.07,13.21,9.01,13,10,13c0.99,0,1.93,0.21,2.78,0.58C13.52,13.9,14,14.62,14,15.43V16 z"
                          />
                        </g>
                      </svg>
                    </span>
      <span class="is-hidden-touch">{{
          "messaging.enter-waiting-room" | translate
        }}</span>
    </div>
  </div>
</nav>
