import { SessionOffer } from './../../entities/session-offer.model';
import { CurrencyService } from './../../shared/services/currency/currency.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from './service/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Profile } from '../../entities/profile.model';
import { Animation } from '../../shared/animations/fade-animation';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { delay } from 'src/app/shared/helpers/delays.helper';
import { User } from 'src/app/entities/user.model';

import moment from 'moment-timezone';
import { OnboardingStep } from '../home/onboarding-box/onboarding-box.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  createDirectoryLink,
  createProfileLink,
} from '../../shared/helpers/navigation_helper';
import { LanguageListPipe } from '../../shared/pipes/language-list.pipe';
import { FooterComponent } from '../../frame/footer/footer.component';
import { UploadDiplomaModalComponent } from '../../shared/components/upload-diploma-modal/upload-diploma-modal.component';
import { ProfileNavModalComponent } from './profile-components/profile-nav-modal/profile-nav-modal.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MapComponent } from './profile-components/map/map.component';
import { DropdownInputComponent } from '../../shared/components/dropdown-input/dropdown-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { OnInitDirective } from '../../shared/directives/element-on-init.directive';
import { TourModalComponent } from './profile-components/tour-modal/tour-modal.component';
import { PhotoUploaderComponent } from '../../shared/photo-uploader/photo-uploader.component';
import { CarouselComponent } from './profile-components/carousel/carousel.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    CarouselComponent,
    PhotoUploaderComponent,
    TourModalComponent,
    NgFor,
    OnInitDirective,
    FormsModule,
    NgSelectModule,
    DropdownInputComponent,
    MapComponent,
    MatProgressSpinner,
    ProfileNavModalComponent,
    UploadDiplomaModalComponent,
    FooterComponent,
    TranslateModule,
    LanguageListPipe,
  ],
})
export class ProfileComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private currencyService: CurrencyService,
    public translate: TranslateService
  ) {
    this.profile = new Profile();
  }

  /**
   *
   * Hide/Show elements via booleans
   */
  public reviewModal: boolean;
  public showPhotoSizeError: boolean;
  public showReviewButton: boolean;
  public showProfileReview: boolean = true;
  public secondaryProfilePresent: boolean = false;
  public secondaryProfileCompleted: boolean = false;
  public showCategoryLabels: boolean = false;
  public showGender: boolean;
  public showIdentity: boolean;
  public loadingPhoto: boolean;
  public slideShowOnboardingIndex: number = 0;
  public slideShowOnboardingEndIndex: number = 0;
  public tourModalHidden = false;
  public identityList: any[] = [];

  public activeTransmission: boolean = false;

  public showDiplomaWindow: boolean;
  public diplomaWindowShown: boolean;
  public showSettingsWindow: boolean;

  public currentUser: User;
  public profile: Profile;
  private profileState: Profile;
  public onboardingStep: OnboardingStep;

  public modal: string = '';
  public languages: any[] = [];
  public identities: any[] = [];
  public jobTitlesAll: any[] = [];
  public jobTitlesEn: any[] = [];
  public jobTitlesDe: any[] = [];
  public categories: string[] = [];
  public categoriesChosen: any[] = [];

  public progress: number;
  private progressProperties: string[] = [
    'images',
    'gender',
    'price_per_session',
    'jobtitle',
    'professional_statement',
    'categories',
    'city',
    'about',
    'approach',
    'education',
    'experience',
    'languages',
    'insurances',
    'format',
  ];
  public nextProperty: string;

  public geosearch: string;
  public geosearching: boolean;
  public georesults: any[] = null;

  public adminAreaMap = {
    Tokyo: 'Tokyo',
    'Los Angeles County': 'Los Angeles',
    İstanbul: 'Istanbul',
  };

  @ViewChild('currencySelect', { static: false }) set currencySelect(select) {
    if (select) {
      select.nativeElement.select2({
        allowClear: false,
      });
    }
  }

  public currencies;
  public timezones;

  public isSecondaryLanguageDropdownOpen: boolean = false;

  public imageWidth: number;
  public imageHeight: number;
  public photos: any[] = [];
  public diplomasProgress: any[] = [];
  public diplomas: any[] = [];
  public therapistData: any;
  public disableLikes: boolean;
  public likes: number;
  public API_URL: string = environment.apiBase;
  public tourOrder: string[] = [
    'profile-image',
    'profile-gender',
    'profile-identity',
    'profile-price',
    'profile-title',
    'profile-category',
    'profile-map',
    'profile-statement',
    'profile-about',
    'profile-approach',
    'profile-education',
    'profile-experiences',
    'profile-professional-start',
    'profile-languages',
    'profile-insurances',
    'profile-communication-format',
  ];
  public imageCroppingState: boolean;

  professionalStartMonths: any[] = [];
  professionalStartYears: number[] = [];
  professionalStartSelectedMonth: number;
  professionalStartSelectedYear: number;

  ngOnInit() {
    this.getCurrentUser();
    this.getProfile();
    this.getCategories();
    this.getJobTitles();
    this.getLanguages();
    this.getIdentities();
    this.currencies = this.currencyService.getCurrencies();
    this.modal = '';
    this.timezones = moment.tz.names();
  }

  private getCurrentUser() {
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        moment().locale(this.currentUser.locale);

        this.initializeMonths();
        this.initializeYears();

        if (this.profile) {
          this.setSelectedMonthAndYear();
        }
      }
    });
  }

  initializeMonths() {
    for (let i = 0; i < 12; i++) {
      this.professionalStartMonths.push({
        value: i,
        viewValue: moment().month(i).format('MMMM'),
      });
    }
  }

  initializeYears() {
    const currentYear = moment().year();
    for (let i = 0; i < 50; i++) {
      this.professionalStartYears.push(currentYear - i);
    }
  }

  setSelectedMonthAndYear() {
    if (this.profile.month_year_started) {
      const momentDate = moment(this.profile.month_year_started);
      this.professionalStartSelectedMonth = momentDate.month();
      this.professionalStartSelectedYear = momentDate.year();
    } else {
      this.professionalStartSelectedMonth = null;
      this.professionalStartSelectedYear = null;
    }
  }

  updateProfessionalStartDateIfChanged() {
    if (
      !this.professionalStartSelectedMonth ||
      !this.professionalStartSelectedYear
    ) {
      if (this.profile.month_year_started) {
        this.profile.month_year_started = null;
        this.updateItem();
      }
      return;
    } else {
      const newDate = moment
        .utc()
        .year(this.professionalStartSelectedYear)
        .month(this.professionalStartSelectedMonth)
        .startOf('month')
        .toDate();

      if (!this.profile.month_year_started) {
        this.profile.month_year_started = newDate;
        this.updateItem();
      } else {
        const newDateString = newDate
          ? moment(newDate).utc().format('YYYY-MM-DD')
          : null;
        const currentDateString = moment
          .utc(this.profile.month_year_started)
          .format('YYYY-MM-DD');

        if (newDateString !== currentDateString) {
          this.profile.month_year_started = newDate;
          this.updateItem();
        }
      }
    }
  }

  /**
   *  Subscribe to any changes
   *  made to profile from other components
   *
   */
  getProfile() {
    this.profileService.profile.subscribe((prof) => {
      if (prof) {
        if (!prof.languages) prof.languages = [];
        prof.images = prof['_images'];
        this.profile = prof;

        // this is just a cheap way of checking if we have already loaded the months with translations
        if (this.professionalStartMonths.length > 0) {
          this.setSelectedMonthAndYear();
        }

        this.updateProgress();
        this.checkIfCategoriesChosen();
        if (
          this.profile.diplomas.length == 0 &&
          this.profile.profile_completed &&
          !this.diplomaWindowShown &&
          environment.name !== 'development'
        ) {
          this.showDiplomaWindow = true;
        }
        this.secondaryProfilePresent =
          this.profile.secondary_language_profile_text != null;
        this.secondaryProfileCompleted =
          this.secondaryProfilePresent &&
          !!this.profile.secondary_language_profile_text.jobtitle &&
          !!this.profile.secondary_language_profile_text.about &&
          !!this.profile.secondary_language_profile_text.approach &&
          !!this.profile.secondary_language_profile_text.education &&
          !!this.profile.secondary_language_profile_text
            .professional_statement &&
          !!this.profile.secondary_language_profile_text.experience;
      }
    });
    this.profileService.getSelfProfile();
  }

  /**
   *  Get location via navigator.geolocation
   *  in service
   *
   *  */
  getLocation() {
    this.profileService.currentLocation.subscribe((loc) => {});
  }

  /**
   * Get categories from backend
   *
   * */
  getCategories() {
    this.profileService.getCategories().subscribe((res) => {
      if (res) {
        this.categories = res;
        // this.sortingService.sortAlphabetically(res, "name")
      }
    });
  }

  /**
   * Get languages from backend
   *
   * */
  getLanguages() {
    this.profileService.getLanguages().subscribe((res) => {
      if (res) {
        this.languages = res;
        // this.sortingService.sortAlphabetically(res, "name")
      }
    });
  }

  getIdentities() {
    this.profileService.getIdentities().subscribe((res) => {
      if (res) {
        this.identities = res;
      }
    });
  }

  transformIdentity(identity) {
    return this.identities.find((i) => i.slug == identity)?.name;
  }

  /**
   * Get job titles from backend
   *
   * */
  getJobTitles() {
    this.profileService.getLocalisedJobTitles().subscribe((response) => {
      const sortAndAddLanguage = (array, language) =>
        Array.from(new Set<string>(array))
          .map((item) => ({ name: item, language }))
          .sort((a, b) => a.name.localeCompare(b.name));

      this.jobTitlesEn = sortAndAddLanguage(response['en'], 'EN');
      this.jobTitlesDe = sortAndAddLanguage(response['de'], 'DE');

      this.jobTitlesAll = [...this.jobTitlesEn, ...this.jobTitlesDe];
    });
  }

  groupByFn = (item) => item.language;

  groupValueFn = (_: string, children: any[]) => ({
    name: children[0].language,
    total: children.length,
  });

  /**
   * Get profile and likes
   *
   * */
  getProfileAndLikes() {
    this.route.params.subscribe((params) => {
      if (this.currentUser.type == 'Client') this.router.navigate(['/home']);
      this.disableLikes = true;
      this.profileService.getSelfProfile();
    });
  }

  /**
   * Resize textarea
   * based on user input
   *
   * @param input Textarea HTMLElement
   *
   */
  resizeTextArea(input: any) {
    input.style.cssText = 'height:auto;';
    input.style.cssText = 'height:' + input.scrollHeight + 'px';
  }

  /**
   * For every filled in property,
   * we calculate progress towards
   * profile completion.
   *
   */
  updateProgress() {
    let filledFields = 0;
    let nextPropertySet = false;
    this.progressProperties.forEach((prop) => {
      let filled = false;

      if (prop == 'insurances') {
        filled = this.profile.insurances.length > 0;
      } else if (prop == 'format') {
        filled =
          this.profile.in_person_supported ||
          this.profile.online_supported ||
          this.profile.text_supported;
      } else {
        if (
          this.profile[prop] &&
          this.profile[prop] !== '' &&
          this.profile[prop].length !== 0
        ) {
          filled = true;
        }
      }

      if (filled) {
        filledFields++;
      } else {
        if (!nextPropertySet) {
          this.nextProperty = prop;
          nextPropertySet = true;
        }
      }
    });
    this.progress = Math.round(
      (filledFields / this.progressProperties.length) * 100
    );

    if (this.profile.profile_completed) {
      this.onboardingStep = OnboardingStep.waiting_for_approval;
    } else {
      this.onboardingStep = OnboardingStep.fill_out_profile;
    }
  }

  showDirectorySettings() {
    this.profileState = JSON.parse(JSON.stringify(this.profile));
    this.showSettingsWindow = true;
  }

  closeDirectorySettings() {
    this.profile = this.profileState;
    this.showSettingsWindow = false;
  }

  saveDirectorySettings() {
    this.showSettingsWindow = false;
    this.updateItem();
  }

  /**
   * Send profile for review,
   * open 'End/Diploma' modal
   *
   */
  public sendForReview(): void {
    this.profile.profile_completed = true;
    this.sharedService.track('profile_complete');
    this.updateItem();
  }

  getAddressComponent(a, keys: string[], address_key: string = 'long_name') {
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let c = a.data.address_components.find((c) => c.types.indexOf(key) >= 0);
      if (c) {
        return c[address_key];
      }
    }
    return null;
  }

  searchAddress() {
    /*     if( !this.geosearch ){
      return
    } */

    this.geosearching = true;
    this.profileService.getGeocode(this.geosearch).subscribe((res) => {
      res = res.map((a) => {
        let city = this.getAddressComponent(a, [
          'city_en',
          'postal_town',
          'locality',
        ]);
        let country = this.getAddressComponent(a, ['country']);
        let country_shortcode = this.getAddressComponent(
          a,
          ['country'],
          'short_name'
        );
        let zip = this.getAddressComponent(a, ['postal_code']);
        let district = this.getAddressComponent(a, [
          'sublocality',
          'neighborhood',
        ]);
        let street = this.getAddressComponent(a, ['route']);
        let number = this.getAddressComponent(a, ['street_number']);
        let premise = this.getAddressComponent(a, ['premise']);

        let sub4 = this.getAddressComponent(a, ['sublocality_level_4']);
        let sub3 = this.getAddressComponent(a, ['sublocality_level_3']);
        let sub2 = this.getAddressComponent(a, ['sublocality_level_2']);
        let sub1 = this.getAddressComponent(a, ['sublocality_level_1']);

        let admin4 = this.getAddressComponent(a, [
          'administrative_area_level_4',
        ]);
        let admin3 = this.getAddressComponent(a, [
          'administrative_area_level_3',
        ]);
        let admin2 = this.getAddressComponent(a, [
          'administrative_area_level_2',
        ]);
        let admin1 = this.getAddressComponent(a, [
          'administrative_area_level_1',
        ]);

        let subs = [sub3, sub4, sub2, sub1].filter((s) => s != null);
        let admins = [admin3, admin4, admin2, admin1].filter((s) => s != null);

        Object.keys(this.adminAreaMap).forEach((key) => {
          if (admins.indexOf(key) >= 0) {
            city = this.adminAreaMap[key];
          }
        });

        let m = {
          display_name: a.data.formatted_address,
          city: city,
          country: country,
          shortcode: country_shortcode,
          zip: zip,
          street: street || subs.join(' '),
          number: number || premise,
          district: district,
          lat: a.data.geometry.location.lat,
          lng: a.data.geometry.location.lng,
        };
        return m;
      });

      if (res.length > 0) {
        const address = res[0];
        this.setAddress(address);
      }
    });
  }

  setAddress(a) {
    (this.profile.city = { name: a.city, slug: '' }),
      (this.profile.street = a.street ? a.street : null);
    this.profile.number = a.number ? a.number : null;
    this.profile.zip = a.zip ? a.zip : null;
    this.profile.lat = a.lat;
    this.profile.lng = a.lng;
    this.profile.district = a.district ? a.district : null;
    this.profile.country = a.country;
    this.profile.country_shortcode = a.shortcode;

    this.georesults = null;
    this.geosearch = a.display_name;
    this.geosearching = false;

    this.updateItemProfile();
  }

  /**
   * Update profile details.
   *
   *  */
  updateItem = () =>
    this.profileService.updateItem(this.profile, this.profile.user.id);

  updateItemProfile = () =>
    this.profileService
      .updateProfile(this.profile, this.profile.id)
      .subscribe();

  /**
   * Open next modal window and
   * scroll to element
   *
   * @param next Next item in tour creation process
   */
  setTourItem(next: boolean, item?: string) {
    if (this.modal === 'profile-professional-start') {
      // this does not map directly to the profile, so we need to update it manually
      this.updateProfessionalStartDateIfChanged();
    }

    if (next) {
      this.modal =
        this.tourOrder[this.tourOrder.indexOf(this.modal) + (next ? 1 : -1)];
    } else {
      this.modal = item;
    }

    delay(50).then(() => {
      this.scrollTo(this.modal);
    });
  }

  private scrollTo(id: string) {
    const elt = document.getElementById(id);
    if (!elt) {
      return;
    }
    const scrollY = elt.getBoundingClientRect().top;
    const offset = 150;
    window.scrollTo({
      behavior: 'smooth',
      top: window.pageYOffset + scrollY - offset,
    });
  }

  /**
   * If categories chosen, show labels,
   * otherwise show button.
   *
   */
  checkIfCategoriesChosen = () => {
    if (!this.profile.categories) {
      this.showCategoryLabels = false;
      this.profile.categories = [];
    } else if (
      this.profile.categories &&
      this.profile.categories.length !== 0
    ) {
      this.showCategoryLabels = true;
    }
  };

  /**
   * Open modal with seleted item
   *
   * @param item
   */
  openModal(item: string) {
    this.modal = item;
  }

  /**
   * Close modal
   *
   */
  closeModal() {
    this.modal = '';
    this.updateItem();
  }

  /**
   * Focus on next element.
   *
   *
   * @param el
   */
  public focus(el: any) {
    if (el == 'title' || el == 'languages' || el == 'categories') {
      let i = document.getElementsByClassName('ng2-tags-container')[0];
      i.id = 'tag_input_focus';
      let e = document.getElementById('tag_input_focus');
      e.click();
    } else if (el == 'website_input') {
      document.getElementById('website_input').focus();
    } else if (el.el) el.el.nativeElement.focus();
    else {
      el.focus();
    }
  }

  setTourModal(state) {
    this.tourModalHidden = state;
  }

  findCurrency(currency) {
    if (!currency) {
      return { symbol: '?' };
    }
    let c = this.currencyService.lookup(currency);
    if (c) {
      return c;
    } else {
      return { symbol: '?' };
    }
  }

  currencyChange(e) {
    this.profile.currency = e.target.value;
  }

  newOffer() {
    this.activeTransmission = true;
    let newOffer = new SessionOffer();
    newOffer.currency = this.profile.currency;
    this.profileService.addSessionOffer(newOffer).subscribe((offer) => {
      this.profile.session_offers.push(offer);
      this.activeTransmission = false;
    });
  }

  removeOffer(id) {
    this.activeTransmission = true;
    this.profileService.removeSessionOffer(id).subscribe((offer) => {
      this.profile.session_offers = this.profile.session_offers.filter(
        (s) => s.id != id
      );
      this.activeTransmission = false;
    });
  }

  updateOffer(offer) {
    this.profileService.updateSessionOffer(offer).subscribe((offer) => {});
  }

  onDiplomaUploaded(diplomas: any) {
    this.showDiplomaWindow = false;
    this.diplomaWindowShown = true;
    this.profile.diplomas = diplomas;
    this.updateItem();
  }

  hasInsurance(insurance) {
    return this.profile.insurances.includes(insurance);
  }

  setInsurance(event) {
    let slug = event.target.name;
    let value = event.target.checked;
    if (value && !this.profile.insurances.includes(slug)) {
      this.profile.insurances.push(slug);
    } else if (!value && this.profile.insurances.includes(slug)) {
      this.profile.insurances = this.profile.insurances.filter(
        (i) => i != slug
      );
    }
  }

  goToDashboard() {
    if (this.progress == 100 && !this.profile.profile_completed) {
      this.sendForReview();
    }
    this.router.navigate(['/home']);
  }

  toggleGenderDropdown() {
    this.showGender = !this.showGender;
  }

  toggleIdentityDropdown() {
    this.showIdentity = !this.showIdentity;
  }

  changeGender(gen) {
    this.profile.gender = gen;
    this.showGender = false;
    this.updateItem();
  }

  hasIdentity(identity) {
    return (
      this.profile.identities && this.profile.identities.includes(identity)
    );
  }

  changeIdentity(ident) {
    if (!this.profile.identities) {
      this.profile.identities = [];
    }

    var idx = this.profile.identities.indexOf(ident);

    if (idx > -1) {
      this.profile.identities.splice(idx, 1);
    } else {
      this.profile.identities.push(ident);
    }

    this.updateItem();
  }

  onShowPriceChange(value: boolean) {
    this.currentUser.profile.showPrice = value;
    this.updateItem();
  }

  getIdentityList() {
    return this.profile.identities
      .map((i) => this.identities.find((_i) => _i.slug == i)?.name)
      .sort()
      .join(', ');
  }

  setSecondaryLanguage(event) {
    const language = event.target.name;
    this.profile.current_secondary_language = language;
    // Setting the text to null will prevent us from overriding the text for the new language on the backend with whatever is currently here
    // This is a workaround for not having proper modelling in our profile service / api
    this.profile.secondary_language_profile_text = null;
    this.isSecondaryLanguageDropdownOpen = false;
    this.secondaryProfilePresent = false;
    this.secondaryProfileCompleted = false;

    this.updateItem();
  }

  protected readonly environment = environment;
  protected readonly createProfileLink = createProfileLink;
  protected readonly createDirectoryLink = createDirectoryLink;
  protected readonly moment = moment;
}
