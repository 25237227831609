<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
>
  <div class="columns is-marginless conversation-columns-container">
    <div class="column is-3 is-hidden-touch is-marginless">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column message-col">
      <div
        class="card has-border  conversation-container"
      >
        <div class="header-container">
          <app-conversation-header
            [otherUserOnlineStatus]="otherUserIsOnline"
            [currentUser]="currentUser"
            [otherUser]="otherUser"
            [conversationIsHidden]="conversation && conversation.archived"
            [showMatchedBadge]="currentUser && currentUser.type == 'Therapist' && conversation && conversation.is_result_of_match"
            (reportOtherUser)="wantsToReportUser = true"
            (hideConversation)="archiveConversation(true)"
            (unhideConversation)="archiveConversation(false)"
          ></app-conversation-header>
        </div>
        <app-eap-client-banner [currentUser]="currentUser" [otherUser]="otherUser"></app-eap-client-banner>
        <app-eap-not-allowed-banner [currentUser]="currentUser"></app-eap-not-allowed-banner>
        <app-stream-conversation-loading *ngIf="((isActiveChannel$ | async) === false) &&((isError$ | async) === false)
      "></app-stream-conversation-loading>
        <stream-channel *ngIf="((isActiveChannel$ | async) === true) || ((isError$ | async) === true)">
          <stream-message-list></stream-message-list>
          <div *ngIf="isSupportChat" class="support-ticket notification">
                <span
                >{{ "support.for-technical-issues" | translate }}
                  <a (click)="requestSupport()">{{
                      "support.support-ticket" | translate
                    }}</a></span
                >
          </div>

          <div *ngIf="!otherUserCanReceiveMessages" class="notification">
                <span>{{ "messaging.other_user_deactivated" | translate}}</span>
          </div>

          <app-stream-message-input
            *ngIf="!isSupportChat && otherUserCanReceiveMessages"
            [conversationArchived]="false"
            (onArchiveConversation)="onSetConversationArchived(true)"
            (onUnarchiveConversation)="onSetConversationArchived(false)"
            (onCreateBooking)="onCreateBooking()"
            (onCreateInvoice)="onCreateInvoice()"
          ></app-stream-message-input>
          <!-- Main messages in the channel -->
          <!-- Thread messages in the channel -->
          <stream-thread name="thread">
            <stream-message-list mode="thread"></stream-message-list>
            <stream-message-input mode="thread"></stream-message-input>
          </stream-thread>
          <!-- Thread messages in the channel -->
        </stream-channel>
        <stream-notification-list></stream-notification-list>
      </div>
    </div>
  </div>

  <!-- Modal for reporting user -->
  <div
    class="modal"
    *ngIf="otherUser"
    [ngClass]="{ 'is-active': wantsToReportUser }"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <a class="delete" (click)="wantsToReportUser = false"></a>
      <h3 class="modal-title">
        {{ "support.report-user" | translate }}: {{ otherUser.name }}
      </h3>
      <p>{{ "support.report-user-text" | translate }}</p>
      <div class="field">
        <label class="label">{{ "support.describe-issue" | translate }}:</label>
        <div class="control">
          <textarea
            class="textarea"
            [(ngModel)]="report.description"
          ></textarea>
        </div>
      </div>
      <p>{{ "support.include-messages" | translate }}</p>
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="report.includeMessages"/>
          {{ "support.include-last-messages" | translate }}
        </label>
      </div>
      <div class="modal-actions">
        <button class="button is-rounded" (click)="wantsToReportUser = false">
          {{ "messaging.cancel" | translate }}
        </button>
        <button
          class="button is-rounded is-danger"
          (click)="reportUser()"
          [ngClass]="{ 'is-loading': isReportingUser }"
        >
          {{ "support.report-user" | translate }}
        </button>
      </div>
    </div>
  </div>

  <ng-template
    #messageActionsBoxTemplate
    let-isMine="isMine"
    let-enabledActions="enabledActions"
    let-message="message"
    let-messageTextHtmlElement="messageTextHtmlElement"
  >
<!--   possible actions here:  https://getstream.io/chat/docs/sdk/angular/concepts/message-interactions/ -->
    <stream-message-actions-box
      [isMine]="isMine"
      [enabledActions]="['delete-own-message', 'read-events']"
      [message]="message"
      [messageTextHtmlElement]="messageTextHtmlElement"
    ></stream-message-actions-box>
  </ng-template>

  <ng-template
    #customAttachmentListTemplate
    let-messageId="messageId"
    let-parentMessageId="parentMessageId"
    let-attachments="attachments"
    let-imageModalStateChangeHandler="imageModalStateChangeHandler"
  >
    <!-- Check for special attachments -->
    <ng-container *ngIf="hasSpecialAttachments(attachments); else defaultList">
      <!-- Display only special attachments -->
      <div *ngFor="let attachment of attachments" class="custom-attachment-container">
        <div *ngIf="isInvoice(attachment)">
          <!-- Custom handling for invoice -->
          <app-invoice-attachment
            [invoice]="attachment"
            [currency]="'EUR'"
            [clientId]="attachment.client_id"
            [currentUser]="currentUser"
          ></app-invoice-attachment>
        </div>
        <div *ngIf="isAppointment(attachment)">
          <app-booking-attachment
            [appointment]="attachment"
            [clientId]="attachment.client_id"
            [profileId]="attachment.profile_id"
            [changeId]="parentMessageId"
            [currentUser]="currentUser"
            [canUseVideo]="canUseVideoCallForAppointment(attachment)"
            [editAppointment]="this.editAppointment.bind(this, attachment)"
          ></app-booking-attachment>
        </div>
        <div *ngIf="isOnlineSessionLog(attachment)">
          <app-log-attachment
            [log]="attachment"
            [currentUser]="currentUser"
            [otherUser]="otherUser"
          ></app-log-attachment>
        </div>
      </div>
    </ng-container>

    <!-- Default attachment list if no special attachments are found -->
    <ng-template #defaultList>
      <stream-attachment-list
        [messageId]="messageId"
        [attachments]="attachments"
        [parentMessageId]="parentMessageId"
        (imageModalStateChange)="imageModalStateChangeHandler($event)"
      ></stream-attachment-list>
    </ng-template>
  </ng-template>

  <!-- Booking form container for therapists -->
  <app-booking-form-container
    *ngIf="currentUser && currentUser.type === 'Therapist'"
    [currentUser]="currentUser"
    #bookingModal
  ></app-booking-form-container>

  <!-- Payment modal -->
  <app-payment-modal></app-payment-modal>
</div>
